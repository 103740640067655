export function removeMask(value: string) {
    return value.replace(/\./g, "").replace(/_/g, "").replace(/-/g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/ /g, "").replace(/\//g, "");
}

export function validarCPF(cpf: any) {
    if (typeof cpf !== 'string') return false
    cpf = cpf.replace(/[^\d]+/g, '')
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
    cpf = cpf.split('').map((el: any) => +el)
    const rest = (count: any) => (cpf.slice(0, count - 12)
        .reduce((soma: any, el: any, index: any) => (soma + el * (count - index)), 0) * 10) % 11 % 10
    return rest(10) === cpf[9] && rest(11) === cpf[10]
}

export function varlidarEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function doubleToString(value: number) {
    if (value > 0) {
        var valorString = value.toFixed(2).split(".");

        var firstHalf = valorString[0];
        var secondtHalf = valorString[1] ? (valorString[1].length === 2 ? valorString[1] : `${valorString[1]}0`) : "00";

        return `${firstHalf},${secondtHalf}`
    }
    return "00,00";
}

export function getCardType(number: string) {
    if (!number || number === '') return "";

    var re;
    number = number.replace(/ /g, '');

    // Elo
    re = new RegExp("^4011|438935|45(1416|76)|50(4175|6699|67|90[4-7])|63(6297|6368)");
    if (number.match(re) != null)
        return "elo";

    // Hipercard
    re = new RegExp("^((606282)|(637095)|(637568)|(637599)|(637609)|(637612))");
    if (number.match(re) != null)
        return "hipercard";

    // Diners
    re = new RegExp("^36");
    if (number.match(re) != null)
        return "diners";

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        return "diners_carte_blanche";

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return "amex";

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number))
        return "master";

    // visa
    re = new RegExp("^4");
    if (number.match(re) != null)
        return "visa";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return "discover";

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return "jcb";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return "visa_electron";

    // Aura
    re = new RegExp("/^(5078\\d{2})(\\d{2})(\\d{11})$/");
    if (number.match(re) != null)
        return "aura";

    return "";
}

export function createCookie(cookieName: string, cookieValue: string, expireInDays: number) {
    var date = new Date();
    date.setDate(date.getDate() + expireInDays);
    document.cookie = cookieName + ' = ' + cookieValue + '; expires = ' + date.toUTCString();
    return true;
};

export function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const getIdpv = () => getParam("idpv=");
export const getVoucher = () => getParam("voucher=");
export const getTestAB = () => getParam("cdt=date");
export const getLeadNome = () => getParam("nome=");
export const getLeadEmail = () => getParam("email=");
export const getLeadTelefone = () => getParam("telefone=");
export const getLeadUp18 = () => getParam("up_18=");
export const getLeadAcceptTerms = () => getParam("accept_terms=");
export const getLeadAcceptNotifications = () => getParam("accept_notifications=");
export const getFbPixelDebug = () => getParam("pixel_debug=");
export const getCampanhaPublicitaria = () => getParam("campanha_publicitaria=");
export const getconvite = () => getParam("convite=");

function getParam(value: string) {
    let params = window.location.search.replace("?", "").split("&");

    let tryParam = params.find(x => x.indexOf(value) > -1);
    let hasParam = tryParam ? tryParam.trim().length > 0 : false;
    let param: any;

    if (hasParam) {
        param = params.find(x => x.indexOf(value) > -1)!.replace(value, "");
    }

    return { value: param, has: hasParam };
}

export const getParamsCampanhaPublicitaria = () => {
    const params = window.location.search.replace("?", "").split("&");
    let listToRemove = [
        "idpv=", 
        "voucher=", 
        "cdt=date", 
        "ab=", 
        "from_lead=", 
        "nome=", 
        "email=", 
        "telefone=", 
        "up_18=", 
        "accept_terms=", 
        "accept_notifications=", 
        "pixel_debug="
      ];
    for (let i = 0; i < listToRemove.length; i++) {
        const element = listToRemove[i];
        let getIndexParam = params.findIndex(x => x.indexOf(element) > -1)
        if (getIndexParam > -1) {
            params.splice(getIndexParam, 1);
        }
    }
    return params.length > 1 ? true : false;
};

export const setEventPageView = (pagePath: string) => {
    var origin = window.location.origin;
    var params = window.location.search;
    var pageReferrer = '';
    var gtmLastHistoryIndex = findLastIndex(window.dataLayer,'event','gtm.historyChange');
    
    if ( pagePath === '/' )
        pageReferrer = document.referrer !== '' && document.referrer !== undefined ? document.referrer : window.location.href;
    
    if ( gtmLastHistoryIndex >= 0 )
        pageReferrer = window.dataLayer[gtmLastHistoryIndex]?.['gtm.oldUrl'];

    // var hasPage = findLastIndex(window.dataLayer,'customEvent',pagePath);
        
    // if ( hasPage < 0 ) {
    //     var dataLayer = window.dataLayer || [];
    //     dataLayer.push({
    //         event: 'page_view',
    //         customEvent: pagePath,
    //         page_location: window.location.href,    // URL completa da página acessada contendo parâmetros.
    //         page_title: 'CDT Checkout',	            // Nome da Página		
    //         page_referrer : pageReferrer,           // URL completa da página acessada anterior contendo parâmetros.
    //         page_path: pagePath,	                // Caminho da página sem parâmetros.	
    //     });
    //     window.history.pushState({ prevUrl: window.location.href }, document.title);
    // }
}

export const findLastIndex = (array, searchKey, searchValue) => {
    var index = array.slice().reverse().findIndex(x => x[searchKey] === searchValue);
    var count = array.length - 1
    var finalIndex = index >= 0 ? count - index : index;
    return finalIndex;
}

/*
 * Use o formato de data americano sendo mm/dd/yyyy
 */
export const dateComparePeriod = (startDate: string, endDate: string) => {
    const today = new Date();
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    today.setHours(0,0,0,0);

    const response = {
        insidePeriod: today >= date1 && today <= date2 ? true : false,
        laterDate: today > date2 ? true : false
    };
    
    return response;
};

export const hash = (info: string) => {
    const utf8 = new TextEncoder().encode(info);
    if( window.location.host.search('localhost') >= 0 || window.location.host.search('.local') >= 0 ) {
        return utf8;      
    }
    return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
        return hashHex;
    });
}

export const redirectIDPV = () => {
    document.cookie = '_idpv=; Max-Age=0';
    var params = '';
    if ( getIdpv().has )
      params = window.location.search;
    else if ( getCookie('_idpv') )
      params = window.location.search ? `${window.location.search}&idpv=${getCookie('_idpv')}` : `?idpv=${getCookie('_idpv')}`;
  
    if ( getIdpv().has || getCookie('_idpv') ) {
        if(window.location.host.search('.stage.') <= -1) {
            return window.location.href = `${window.location.host.search('.local') >= 0 ? 'http://' : 'https://'}idpv.${window.location.host.search('.dev.') >= 0 ? 'dev.' : ''}cartaodetodos${window.location.host.search('.local') >= 0 ? '.local:3000' : '.com.br'}${params}`;
        }
    }
}

export const redirectPageIDPVsNew3DS = () => {
    document.cookie = '_idpv=; Max-Age=0';
    let params = '';
    if (getIdpv().has) {
        params = window.location.search;
    } else if (getCookie('_idpv')) {
        params = window.location.search ? `${window.location.search}&idpv=${getCookie('_idpv')}` : `?idpv=${getCookie('_idpv')}`;
    }

    if (getIdpv().has || getCookie('_idpv')) {
        // Muda a URL para o novo destino
        return window.location.href = `https://idpv.stage.cartaodetodos.com.br${params}`;
    }
}


export const acceptIDPVs3DS = (idpv: string) => {
    const idpvAcceptList = [
        //hml
        "f7efcd40-7977-4be0-976c-32515c251076",

        //prod
        "d86f9e83-1985-4757-9fcd-bf43bd54f965",
        "d674e71b-b401-4fe1-8354-cc6285caaa83",
        "eb8b39d2-2e63-41e2-b22b-c5c268c6ab0c",
        "d751fe60-0573-4ff0-bc97-5f44293a43a9",
        "3dcd8b2d-ba2c-4a44-ae28-d41dc254a76a",
        "8987d545-f4c1-4b3b-af00-4a72a0ea72d6",
        "dfd5ba02-2ac3-42a1-91ce-2c2f3c0d89a5",
        "dca86b7f-5026-4bb8-ab27-60a408a64eeb",
        "af1474ee-9f30-4f95-b4ab-de3f5ecc09a9",
        "6e85399c-d14a-4445-b674-520eaec1e409",
        "4fecafe8-81af-4831-b6d4-65ca2e109607",
        "8185344b-9c4c-4c12-8de0-6583c89fb703",
        "13d22073-b330-468b-a21a-07b26f1fd471",
        "c6d40ceb-b86c-4065-988d-36c880f7691f",
        "254275b0-534f-408f-a62a-0b34f3ca5d64",
        "6d9d4dc0-e838-4bc5-a8fd-bc69d527846a",
        "c0bf3ccc-bf7e-40fc-ba9b-32f8b798e84f",
        "fcde73bf-f30c-49fc-8dfc-cad57097bcb3"
    ];

    return idpvAcceptList.findIndex(x => x === idpv) > -1;
}

export const acceptIDPVsRefiliacaoPiloto = (idpv: string) => {
    if (idpv === undefined || idpv === null) return false;
    
    var idpvAcceptList = [
        // HML
        "f7efcd40-7977-4be0-976c-32515c251076",
        "e7391feb-1156-4ead-a5fc-eeedcffa9f06",
        
        // PROD
        "45616C13-4FB2-4B9E-A637-9750D2CE91FC",
        // MAGE
        "66179A4D-9B3E-42BD-979B-EC0C80014907",
        "484AC803-BDF8-4B5D-96FE-3BFA4E15D009",
        "7C0CEA7C-E781-40F6-A22C-23DAC178B6B5",
        "C6563D4C-DA2D-48A8-9581-D4CE892DB98D",
        "0750B3E9-7A61-45B3-AABF-4F74569062C6",
        "BEAC14FA-C7D6-49A1-9BFF-3424B5956E7E",
        "4FD12768-4A1A-4E72-AAA6-E31C64777B12",
        "151A9049-5AD8-4C30-836A-D7E4363EBA59",
        "01D0A94B-80A2-4FC1-8FD3-6006868BF5FD",
        "376099AD-FDDA-4BF6-B3BE-029DE1782BC6",
        "EF5E14CE-1B91-47E4-9A84-00B9E6143A4A",
        "BD4C57F0-447D-4F4D-A1E6-09C286D5D17A",
        "BAE48F19-16CB-4443-B3D5-2FC902171859",
        "78009195-75F3-4EC8-9AA3-06E420B2F9DA",
        "C1B820EC-ABBF-40EB-8315-AC464F8242C3",
        "777F2377-6F29-48F8-9AF6-3361CB1FAE86",
        "55EB70A3-1A4F-4002-99A8-5B79E3CD204D",
        "BDEB16BF-7713-4754-8C99-A3E0C2ED293D",
        "5AAB8FDD-A84F-43E4-A7FB-1C3F5E02EAF1",
        "BFB00EFD-54EA-4178-8A60-F334F21228B1",
        "8AB28599-8163-4D88-B18E-52DDB59068C2",
        "27DEF0E3-F549-4BE3-B2DE-85B65E2654A1",
        "72738FEA-9779-4BB4-A6C8-903A11CF8EE2",
        "2F1B2D3B-F75B-4D9C-BED1-EB0D5F5E31EC",
        "932C88BD-1657-462B-B70E-9395FB2D08BF",
        "873AE10F-7615-4CDB-B09A-350444A1C097",
        "671069A9-563D-4424-B597-8D9B38574612",
        "6796199B-8102-43F3-96EB-F9CE7CC47842",
        "8A5F6D1C-5B12-4A93-AC03-B3AA67860122",
        "46692E1D-C126-44F5-81AF-1F995A790D70",
        "D89E47D9-9268-4013-AC43-2E80AC77B396",
        "104AD628-18BD-481D-98DD-53CFAFE24066",
        "05EADBDD-645B-4FB3-A9FB-88DD94D7869E",
        "C8F0C486-3D4A-4937-99D9-692773503443",
        "A5E34423-4C29-43F3-B86F-BD443EDCEDF1",

        // MARACANAU
        "CC84958B-BC9D-488C-A5EB-B99A5D234305",
        "EC2F89C0-AF2C-460A-A830-FE52EA6BE7A9",
        "5DFCEF6A-3AF9-4DD5-9F6D-5F29D20419DE",
        "D69AC163-5DF2-4EDC-B68E-C1B378B0364F",
        "9BC9A381-5FBD-4BC6-94B3-37F378B4B93A",
        "8E9464DA-2B8C-468A-BBA5-1BFC1F3EFAB8",
        "8DB72D11-69E9-4D0A-AF66-65CA15796B4C",
        "620F0625-C80F-4322-9D94-346C28D0E15A",
        "B9101177-7861-4F38-B008-9DB2A6879596",
        "22EA33CA-D35D-470E-BEE5-E8389F1A310E",
        "F555828A-F21F-4710-A139-4AD8F9D301D7",
        "7262F8A5-7B42-4F5C-80B9-0ABB83B2C29A",
        "49410D80-A0F7-4278-A651-66E806A96BD3",
        "B59E95EE-962E-4A8F-AED0-5D4F692FF4F9",
        "1E623A74-E71F-449F-9E8D-CF41A859C051",
        "0C5FEBFB-0EC1-486A-96D0-680ABCEC58DC",
        "D2EBFBF9-3EED-4AC0-B053-120B3D34A2B8",
        "4031E9CA-B04A-4412-AA7F-419C86DDABB0",
        "D43AE2F2-4309-4BF2-8172-D371BB3B9C53",
        "AE9390A4-D26E-436E-B898-9FBC25B00679",
        "CAD52CF1-9550-4B95-976A-B070E415A3F3",
        "E18DEB7E-7F4D-4A72-BA66-3F9452A2A862",
        "9058B0EB-9430-4F32-ABBB-1133F69349FE",

        // CARIACICA
        "DA99F5AC-6905-4C03-883D-300712DD3F81",
        "24679230-FA99-4AD5-92E7-CE980772E4FA",
        "756E6275-FB7D-42AA-9B4D-54CE7B256753",
        "C406FF05-47D1-4DAF-84F6-4416D33993D1",
        "584FC673-D703-4446-ACB0-1233055C8ACD",
        "9EA43804-8DB5-4498-B9F2-B07E45024859",
        "6E8191D7-2A7D-44A3-8B34-C139E19AC2CC",
        "4D1E81BF-8FCD-4223-B7C6-905C9B31D1B5",
        "7E6E644E-A620-4B83-BD62-423451A8A575",
        "D9AFB79A-74FA-4524-A0B7-888AD1BA3D52",
        "7DB5FFD2-0F46-4AAD-8233-633424230C8D",
        "0A2D871E-24D9-43D8-A78A-B393016B2662",
        "AB30F806-617E-486D-A080-73BD33A80C37",
        "50E2BD6F-9782-4A9E-8DE9-D0FF2B95CAD8",
        "418216DF-494E-43C3-AA3A-4DF97CF8EA24",
        "57A25437-5157-4E2D-AD1E-2FCADF28F5EF",
        "B9180A19-7DD5-4CAE-9D3A-BE1C0E467991",
        "D017FC1C-CD57-450C-B1F4-ADE55A4DB192",
        "48F8C448-DA8F-4281-82FF-53AF2D2E2BE4",
        "5B0D32E0-8A71-4241-989C-8FCCEB497740",
        "DC252BCD-612A-4AE8-8A72-937163BC5272",
        "8469494B-9B5E-405B-95EA-4B9695B595E8",
        "EB0E633C-A774-49CC-A78B-BFFB85828319",
        "62F57B88-227B-4B3E-B228-7F76F8DAFF86",
        "9282612F-DE89-4B48-B753-AF38661FD4BA",
        "7A195156-1367-49AF-891A-F4EF94D6514B",
        "9C5682DA-7C37-47BE-A447-84F71F090E97",
        "9A40A615-746A-47A4-BA15-3532917B47BD",
        "01E76C56-8A3F-4173-A5A2-326279EDC29D",
        "796181AF-EEE6-40BB-B134-92AAC3AAA3DD",
        "EFF4E557-51D4-4F1C-9669-EC79D983CC42",
        "DD8D58A7-14CE-4E29-ABB5-DEC8ACEB95AA",
        "F188A62B-E9D1-48B9-9FE7-C032E27335A6",
        "BBCC4BDF-1837-43B7-9D1A-F4F95F2ABC5C",
        
        //FEIRA DE SANTANA
        "B30185CB-EF5B-4477-8E7B-FBD453926F70",
        "BACE502A-3012-4823-96F7-55848F3FE2CF",
        "F845BE99-D6D5-4AE4-94B7-94F4925D6810",
        "39ACB3E9-7ABE-4343-9E56-55F848C62E51",
        "40FAA97B-708B-4567-A45C-E9E7766B6C88",
        "694D7361-47B2-42C4-98CE-5F6E4EBFD42A",
        "AC2A71E1-2954-49BD-88C8-CAAF2CE86276",
        "BAFCA916-77DE-4E67-A712-7B4466452808",
        "AD44A93A-440F-420C-83CC-0C32AACE9EB8",
        "76B980EA-7794-4E5E-8A69-C44BE8638A75",
        "58190B06-342F-417A-BB4A-49ED92F8620A",
        "091FA10A-D9A8-4DB7-92BD-7E56CD0879CB",
        "31F359E4-C244-4820-8BB5-767C0072A79F",
        "9657F20F-0DD9-4E45-AD60-437C246447A8",
        "96D47113-C949-44D4-9E2C-172DD22D904D",
        "99E046D2-7647-48CF-AF3D-881A95CA9029",
        "49EEF42B-37FB-483D-911A-D6A0B58580AF",
        "6311950E-7FD8-4040-8272-7FA352A356FD",
        "499D1990-BEDD-4494-BC54-B5124995A951",
        "7E241176-4631-4081-BBEE-22DBDA371923",
        "E93C274C-A6B6-464B-AE87-E16BEAC2CDDD",
        "DA80036F-A723-4027-9BEA-D4A323B8BE52",
        "7D797981-43C8-4B5D-A7D2-C4A4C29C7185",
        "7209B137-7362-4DDC-9B70-9F29CCC7CD74",
        "0FDA07E1-CA82-4331-9F1A-B900A61C4517",
        "36862356-BB6B-4739-B959-D3412CF26FA4",
        "81C8F25A-A50A-4625-99F6-9480F435A69C",
        "C3E603C0-50F0-4888-87E7-23ACCEF789B3",
        "B51D7612-5363-48B2-82ED-29576585D813",
        "39957E0C-A34C-4176-9FEA-FAF05DB7DCE0",
        "A2C243B8-49C3-4657-BE1C-4FB3113CEE0F",
        "51D186D4-36B1-4AB8-9FBB-F3F9A6922C9B",
        "6AF29323-BE35-4AA6-AE11-C77EBC27CA84",
        "E9CD5CAE-734E-4674-930E-ABF04D11A952",
        "3A534CC4-6B60-4DE9-807E-0F9C637B7372",
        "D2693B3D-5F48-434D-A73A-BCB8AB3FF271",
        "AA667605-B08B-429D-8E69-849C10313742",
        "BE93DEDA-63F9-404D-8970-4B52C9ED34C7",
        "13D0B41A-F2DD-4611-ACC1-7E86A0D82DDC",
        "7FE7F407-2B91-422A-AD5B-DC24820E2153",
        "6BE2BD34-7BB6-4915-B374-74AF8A4CED29",
        "E94A5585-D88C-4983-8B08-8C45DA6C5F64",
        "D5A04E03-6B05-4C3A-9126-ED1BD8E555FA",
        "973B11A5-672E-44A4-982E-660E583CDE35",
        "5A10187E-D6C1-4DC7-BBE6-F1406434ACBE",
        "6C2480D6-216F-42DE-9753-E6FF74D6BBE7",
        "58240E8B-FB1F-4191-A861-06F62A8BA366",

        // VITORIA DA CONQUISTA
        "CBF23804-B9C4-491C-9E81-F9D94DAFA090",
        "1D23A442-5324-4667-A1D3-CA69DE8AA406",
        "5A606394-F0C0-44D4-B1B9-B6C6369043A4",
        "21F9A012-2BDF-4634-8750-561210AC7910",
        "78733ECC-E11E-429C-A38B-F790C01E0781",
        "1C1AC093-4F72-475E-9DB4-CC5F90534DFC",
        "3FD7F649-C7B2-44F0-928D-36ED50FB6D1F",
        "E041ECF8-C8DD-4F77-8079-1697093414B1",
        "9DE72AD5-5FBB-4EE2-990C-FA6BE64B8182",
        "271C6BB1-7157-4617-82C6-2A4838519926",
        "FF25231D-3149-4EB7-8850-94902C6324A3",
        "13FACDCD-0B36-41F3-8E3E-2158ED196EC0",
        "89C1F0DB-0F8B-4306-BF18-443EB5BFC3D0",
        "7691B377-64CD-4880-8B08-C790F07349B8",
        "C0598CBB-3A60-42CF-B461-AB0C72686B82",
        "CAA9BACB-1EB6-49D1-924B-848099A7A819",
        "38B233B0-8E87-4AEE-8EC9-82E1F5ADCD13",
        "3AF2A062-7851-4B15-8C91-1D975B8B130C",
        "F27B48AC-DBD8-49D2-9E20-29AFBAED7FAA",
        "DCB1B6F2-073D-40F1-8E5B-E9621CA23467",
        "E8A05585-3266-409D-9B60-63C17D73AE3B",
        "A9DE3042-6A69-4022-AD15-58B4F355D7A6",
        "91690392-9419-450C-AD98-D928E8C028E2",
        "B55A3214-12AF-4B16-B1CA-318131006D48",
        "95137968-A58D-4247-A909-078E33345173",
        "04FF6810-3C44-4422-83A8-A874A30A1D99",
        "9727DF48-6A85-4B51-A624-C7751778AF3C",
        "EC56BE28-878E-4AC9-9CB1-3753CC42DB58",
        "E42AA831-60AD-48AC-BD21-951704590A80",
        "F2896D12-1E05-48F3-A8B5-0A1CB71EE601",
        "B9332654-94C7-4BF5-91F3-76556F9A11B1",
        "492431D5-F1A2-4448-9283-5E2D661C0AF3",
        "15C25DFE-4F4D-4FC5-8E46-6BB33E46D3BE",
        "03C97DD8-75D2-418F-B036-B085D8AED2D5",
        "97087D2E-83CB-412A-89CB-9B2F7F910E2E",
        "D0A1225F-36DF-4E61-924C-DC628844753A",
        "1F8D4D3C-117F-49D9-AF6A-69406C9532AC",
        "08A54A36-D00C-4ADC-A815-7424EDE78E43",
        "62452CFC-B805-49E5-BDB6-24B8167E21CA",
        "124BB386-316C-4AB8-9FAB-612DF2318332",
        "DCF621DC-64C7-431D-8FE3-7581A7C77AC4",
        "FEFAFDE1-3AC5-407A-8875-275B3247097B",
        "A78B9AF0-C63D-441E-B527-702DE6AA3057",
        "98AB0AA2-D41E-4FC3-AD60-A0472EEE2AAF",
        "C2C9AA24-20FF-434D-A54E-2694809DA9D2",
        "7EFEEB27-D8CC-4A0B-B282-C9097FDA5A0C",
        "0CF5D30C-5875-45A6-BCE6-5F426C723BE2",
        "27BFBEEE-DC8D-4D38-837C-FB11B91BB74F",
        "57321007-D784-4245-9071-814EA28BBC27",
        "5DC4316C-3F46-47D2-94F7-CBB8F6ED6DE2",
        "FA284B43-D1CD-4024-96E8-C2369F9FAA1F",
        "FF9768C9-F909-4787-B1EB-1F2AD5AF7A88",
        "0C7B12F3-9C66-4912-B00F-9C485C3FF62F",
        "FE873E03-8BB1-4ACC-978D-6BC7ABC70D5C",
        "4D4A5245-6308-4780-90A4-4877009141B9",
        "021AB35C-880F-45CD-AA40-FB320C11E9A2",
        "14F9F090-8E72-4145-99B5-7CB2218E4AB9",
        "4088B881-4B27-4ACC-84E5-52CCF77585B8",
        "0E4FD9DC-A103-4EB8-A180-FB54DC92A709",
        "F4088D7D-000F-427C-894B-A79EFCE65C74",
        "FA1FAC82-B719-4870-B66D-BCA05DBB354C",
        "0879A936-394C-4618-864C-DDF6C0A6E536",
        "1E741A52-3099-465C-B3B9-A1BBABE82F22",
        "8D53A6D7-96A8-4180-8A5B-AB84AA0758E1",
        "9E24E0FA-EE80-4F96-8495-6EAE2FB128CD",
        "2C3352AF-EEFF-4599-8672-852FCF5BDB19",
        "4623F926-792E-48F3-927B-D318EC8E5FD1",
        "7683A8A5-00B5-47FA-A63B-CC18B8396CBE",
        "97D01B1C-8FC2-495A-ABBA-55D745680F35",
        "DE6351B9-C9F3-4812-B832-2710C7CA1156",
        "58C2C3C4-19F8-4ACB-86A9-5FA058D0D3B9",
        "4CC150AD-C627-4513-927B-41C5357ABCE3",
        "61BEE2D8-0F7A-4E7C-ADF8-26FBF9982AC1",
        "735DF465-D17E-4345-8AC2-C6D446BB57AF",
        "404BAC04-49E1-412B-88C1-8A3176954F4C",
        "228B9FBA-24D5-447C-8B6D-0EED172F7037",
        "202217B4-04AB-44B0-A891-B61FEB806F1C",
        "EAE8422C-52E1-4B72-964F-DE9AF826547A",
        "B1CEFC95-1F9D-4052-894E-B317C1672CCF",
        "7F4DDA5A-37FA-43BF-B4B1-F61D3B4EDB56",
        "A422D165-1482-41E1-BC45-65228CDD999E",
        
        // LAURO DE FREITAS
        "82AE5C43-599E-408B-BF89-17DB2AB31BD9",
        "7152BA89-C20A-4578-BE5B-F46D83002B0A",
        "B3E0849F-E140-4EC3-A365-0AD6112A2AAA",
        "78B2E917-D548-4A19-8C97-E397C6D2E387",
        "5AA53C99-14CF-4455-A0BB-07265E7B6ED4",
        "3D3D28A6-CCCE-4DE5-94F8-72C376846B86",
        "D237C517-7E53-4EE5-80B2-F1F3A8226671",
        "A5B2D3D2-5160-45A3-8FC8-9199C3907953",
        "11FB025E-EB10-41EC-A3D6-6C3540146F24",
        "7458CC76-35DB-4032-9A8F-2A58062E6F93",
        "3D600D7D-F7BC-41A0-B9DB-4927DD65CCA9",
        "5BAB6A4B-B279-4764-A95A-2FBB185FC6AE",
        "8A25DE84-99AC-461C-8546-CF75ADB965B0",
        "11E582C3-6FFB-4D76-817C-B19E560352F2",
        "694B584F-EBA2-4DE3-960C-08BB796AA76C",
        "C7D404C1-6BC3-4899-9517-6DAB8C08EA4D",
        "99A14CFC-5F27-42A1-BE28-1FB9E95B881A",
        "D519F6F7-0B7F-4946-92DA-9D704D5A68F9",
        "2F6045D7-CF6A-4F2B-8272-D300E34B6C33",
        "4512D7D1-3884-468F-A9F3-0416769C939E",
        "205BC834-766E-4D5E-AB25-B00337BC9E9F",
        "27FF0230-3A6F-400D-AC10-9A05060BF196",
        "7C18E050-9E30-44A4-9495-61E15DF5637C",
        "C06EF44A-294B-4039-B243-7F165B0B3487",
        "B0FDEF9A-C2CC-446A-BEE4-A6494D7C910F",
        "E29958F4-B554-43F6-8C7D-E4A8240E7D1E",
    ];
    
    return idpvAcceptList.findIndex(x => x.toUpperCase() === idpv.toUpperCase()) > -1;
}

export const acceptIDPVsCampanha = (idpv: string) => {
    const idpvAcceptList = [
        //hml
        "f7efcd40-7977-4be0-976c-32515c251076",

        //prod
        "586B8E68-532D-4555-BE47-E77F04EAB0A4",
        "0A0782DB-DD9A-4D94-BF45-1C5C66106E67",
        "97067E09-8489-43F9-A7E5-8C92E1102C28",
        "3C207C20-091D-4C7C-8317-3ADD5DDAF062",
        "925014B1-569D-4B9F-A57C-3B406E7E94FE",
        "670057D0-E071-49AE-8F65-660F95383669",
        "21D43ADB-D070-4EF4-9363-F837AF940555",
        "186A8F55-B240-44A7-AD3F-080BFFEF0625",
        "201652CF-9EF1-49CA-8BB2-DF63B7FE3897",
        "F3B7E2E4-89F1-4AC9-B3A7-8A7C6FB711B5",
        "0420E4E9-0188-4E9B-A5AC-7D188C84537B",
        "D015931A-5C07-4619-84F2-1AD7E289B989",
        "0AF641B7-6649-4FFE-9D16-C74880E7A7F8",
        "71C64844-B5F9-4DDA-A937-1B088CF1CAE9",
        "D73B13D1-F731-4304-8BF0-A00CFFD60371",
        "E142B1A1-B5B7-4D86-85E8-D2F3CB298EFC",
        "9B2A2D80-0EDD-44CB-86F3-C3762206FC9A",
        "9EBE137B-984B-4D28-8563-E8CF3D10EE93",
        "F8F19962-5D36-4736-9C6B-84928B81615E",
        "0AC999AF-F1CD-43B1-93B5-7CCA3B9026F8",
        "6AD9D92C-B493-4713-9DF6-48F89B715CBB",
        "2B60C36E-0D2E-4C5B-8814-7254476B5DC3",
        "449C806C-B8A6-4D9A-A86D-741BE05025C3",
        "9AD29F66-DA4E-4E9C-9F05-349C85E3D4AD",
        "A3CE13D4-2EC2-4848-BAA1-8AEE510B85E8",
        "423AE302-8B4C-47C1-A903-8E3CC75E0039",
        "8A1CE7A0-4457-4B02-984A-6C7D2FBF7862",
        "0C94B849-ABB8-456B-8D10-5218F6E0E612",
        "0857F5AB-84AF-43EF-9B8C-F63D95940116",
        "0163134C-2086-4F15-A747-4E6BC18DC811",
        "7E5EBE1A-9008-44E6-9E43-F53FD6AB7181",
        "DA18F39D-FA02-4966-9918-C4EDAA4924FC",
        "15FEDA62-2DC9-42F8-A671-9B3FDB37A501",
        "1A4576F5-4A98-44C3-A34E-0DD5C5578C32",
        "1657480C-8596-4C8A-9485-89D7F62E7698",
        "1EE2B9A3-43DE-4B84-9E24-329CD1CF8DAC",
        "96556A2C-7708-40C0-9BC6-D7CBFCF177AD",
        "FEF5054A-8FD0-456D-AACA-20346DDD599E",
        "4103BEB9-AEC3-4375-8CD5-2DB662286D28",
        "96A95CC3-1116-4572-A8C3-F8B964A26374",
        "2C17E442-5946-497A-B2AF-B6C123AEB5B2",
        "6FE34C68-ED14-4F55-B678-C62F686050ED",
        "F1F68CD8-C3B8-4A58-9522-998F82EB5924",
        "09CF6282-F01F-4C58-AAF0-7B283AE93487",
        "FC6E6CA3-ABEA-49BD-9963-41C6A1EFCB46",
        "F69195DA-57A4-469C-9897-DAE81CFFA59E",
        "8BA98E3C-FF34-4133-9143-0CACC3204350",
        "C5DDED0B-A06E-4F7C-ADDC-673061CA8CE6",
        "3D07AB41-6604-4215-B21B-713DD076917C",
        "F9129573-0FEF-4A0A-9002-EDA119F7941F",
        "8F611B1A-CD59-4E69-BB2C-C5EA250763E5",
        "D26EE257-A04D-4700-8869-42E0B7C9FC12",
        "B823C560-4554-439F-968A-5A8ADBEC6AA9",
        "DAE6991F-F640-4FD6-848B-4A0915EEB981",
        "59FBC0EF-A66E-411B-9F55-FC029BD82614",
        "A7C2C6A1-1309-49EC-BB10-F56BF7B9E17B",
        "56D582F9-174D-40F4-8696-4C94FA11EC4E",
        "1E759C45-681B-4174-BCB3-6E26B54F927B",
        "AA985C82-FB53-4F1F-967D-96C72CA54BAE",
        "D2EAB805-16C1-4F9B-A6BD-2DA33B87C92F",
        "3ACF65A2-1B5D-4335-BD3A-F24C20408881",
        "8017A0C8-47C1-4AF7-8255-163A5A952BE8",
        "7FC94F7D-C54B-4052-87DD-6C2109F1905B",
        "D10EB5BE-7A47-4FF4-9E69-1F1CB6CBE088",
        "75B73BF9-CBF9-4955-BAF3-C6A29B52497F",
        "BBDC473D-E063-460F-B151-00D2CFAB9720",
        "D5457328-8272-40DD-B915-28673B85C25C",
        "8D74B028-801B-4849-B23D-6A3B24EACB33",
        "D9761DD8-DACC-4C17-87CF-A0AA2A7C1E2C",
        "6A75A090-D350-4F44-8947-A87767F523F8",
        "ECD0E00E-8956-4DE5-9103-0E1E649E5E4B",
        "30965365-9649-4F05-B3EC-B1BE82EE8745",
        "C47628D7-2E23-4B55-974B-35240EE579FE",
        "4C567842-A9C2-4FE1-BFC4-8BB9CFE6A84A",
        "DBF11A44-E3E5-4810-93AB-C78B9C3C6E28",
        "A10680A0-B893-477B-B3CA-44D72C12C662",
        "52C89593-AEEF-4420-B8FA-8C212E1E4E4C",
        "51073332-1D65-4D14-BC7C-6354A13349EA",
        "DDEDFBAB-2B40-4729-B5D5-CAD8DBE1F06C",
        "265BF150-79B0-4508-AB78-03274B2D0CAB",
        "72227E8F-AB12-4052-8B0E-9608C853B4B2",
        "1994FB1B-5344-4767-A995-875254DCDD9C",
        "618D9868-C677-4613-A345-867CCEF8E2BE",
        "4931BF43-0D03-48DA-B94C-9689728F8691",
        "169A3A9D-8C68-4F0F-9BB9-940772353E29",
        "9F3BC83E-405C-4D1F-986A-FB7FFCEED642",
        "72B96C9E-D120-47D3-9864-2E57B3347B29",
        "806D4A98-6ED8-4E6D-A878-6F41CED4FCAB",
        "AD18D8BA-D618-40BA-8928-7A7D429A0DCE",
        "98136926-5211-41F1-956F-1C3BB46EFE35",
        "8D9280BC-E69A-40F2-9D52-1DED5529D45C",
        "07F9E6C0-453C-4122-88A6-E541412E4693",
        "733B41C8-1BAD-4B93-A1C4-816CBD34D5B6",
        "FBA61521-C3A2-4293-A221-975F399D3A4A",
        "8435FC73-9D90-420D-A8D5-3063AE2DFAFE",
        "7C4C9EC7-6C2F-47CD-AB66-A8A5E4297D3F",
        "46F64B5B-053A-44AF-8976-B490D2F06C71",
        "15D012C1-927D-4790-9CEE-43FB709AD99F",
        "4B2D1813-F1CC-4EF5-9BE7-995D02FC2135",
        "4C8A44EA-E94B-4618-B1A9-49E50542A4DF",
        "8DDA9177-2F10-49C3-9BC2-89DA67E080AA",
        "4591CAFB-ED3F-4BE9-B63C-2AEE6860B37A",
        "5E7D60DF-DF1E-420B-B726-6F6B386441C0",
        "5D742ED1-0B20-4BAA-ABBD-BD83F47F91DF",
        "A690B2BA-DDA0-406F-B560-D801FE7E5F4B",
        "A92BC478-F4FD-452F-8A62-9985217CE655",
        "682DCA29-829C-46E3-A490-426DB04CB03D",
        "3DE681D8-84A4-4EA6-B3FB-829D4F4CB403",
        "F2A96794-6F5D-4627-9D02-6204A10AD59C",
        "0EA876A9-4863-4F3D-8964-133798C4EB60",
        "77E16F62-3DD7-4C34-BC72-444E3774D1D0",
        "BD0D1104-C3E9-48F4-9F41-BB4FBCE60C7B",
        "8DB60C99-40A7-4E6E-B1DF-32F34DC09E71",
        "6B84C28C-C0CC-4B06-9FA1-F4A9561DD310",
        "73E15016-3449-4EAD-A97A-F8CA72E31337",
        "7AE851C6-E44F-4550-878B-14EA66F449A2",
        "EC2168B2-40C5-4B7F-915B-7F5B82880125",
        "CB985004-FEFE-4B87-A467-EA36CF78E1F1",
        "BD5E2BF6-1DEA-44BE-9E00-9749EB78C3D7",
        "A923A761-4996-4C6D-B80F-642793082E16",
        "55FF8F4C-3B2E-41D6-9CDE-B20713EB825C",
        "58717B38-E7A2-47CA-B6ED-F3031C0FE1EB",
        "8503E4B1-1B89-4C9B-924B-5E2836D3AEA0",
        "F471C595-7CA4-42A3-8F36-F9CD7A8C5970",
        "F2A057C3-301B-4A07-8CE1-CC0BB675266B",
        "C908BD87-FBAF-4CFF-9B3E-74084E8DC182",
        "1C33B4C0-3097-47E5-B025-54FAD0EB1B95",
        "06AEA1C9-1DA8-4C14-9390-0FEF05E9FE38",
        "83EFB612-2182-48B0-98FD-66066F227E4F",
        "0AAE4E4C-5ADD-4C60-A78A-3C3F2D5F2EC3",
        "7C062B86-94F2-4D20-84EB-F5A08E1EB742",
        "9B876E4A-64D6-4E43-B2B2-EF6E931B9E22",
        "A3630170-BD5A-4371-B544-5ECA4535BE73",
        "4A643B2D-29C0-4B68-BF18-39238B152ECD",
        "25E5D85C-DA5A-4D0B-B9DE-6EE7CAEA9E33",
        "0346185C-C01F-4398-9157-67B086C131BD",
        "28D48725-5935-4183-9AEC-10F3D0C960D2",
        "465BC40F-2BB8-4CB9-9D04-328718FF2D65",
        "C1313417-D4C3-40F3-9BAC-C801BE6E539B",
        "FCBD2DA3-1EE3-4112-9DF1-65CF0DCDD955",
        "3C724FF0-9C2D-4A8F-8BCD-2F4BF7BF25CE",
        "115F6843-87EF-4906-BC38-6A22A0F31BE3",
        "F90A6944-4F3A-400C-9A73-9B552D0F5738",
        "153AD8AB-A276-422F-9BC2-95D2D1F4D54A",
        "3DA24DFB-DE67-4E91-A80B-BEA27E0BDFC4",
        "3AD30A67-56CA-4175-A106-19C9B9A93F93",
        "1EED47FF-39F1-4995-87A8-8A5FDEEA3E8F",
        "941C2040-D47C-486A-AD8F-B5613ACC0F3C",
        "6084BA21-9E0B-4237-B342-A2FB7821531E",
        "B0BBC781-9E72-44E1-AFD0-E37102C3DE90",
        "0EAE8AB7-CC09-40CD-B204-D00157F03F87",
        "3DAE37E5-ADEF-4D9D-AE44-BD400DE32839",
        "47C0A02C-F686-4A3F-AD81-DF2E57F517E2",
        "DF99D6C9-4551-495A-A7CB-812A74964CD6",
        "482EBD77-C688-40AA-B689-51456CD46598",
        "B152AB18-22A3-4B37-85D0-F0AC06BBAE45",
        "F7101511-7002-46B6-BEA2-20B2C620857F",
        "B69ACB92-639A-49A6-A261-0EA48C3E387D",
        "F0A3A24A-7AC3-478A-9591-3F1AAD38921C",
        "461F6684-3805-498C-9ABE-A10B52F959BD",
        "0415CB1A-66CC-4448-ACB9-90E68EEFE690",
        "4D9EFD02-B782-4307-B192-8CD76094FF92",
        "5DAA4A20-89FC-4C14-B6DA-CC0FDEA97BB7",
        "D9AF870B-A52E-47A4-9D0C-7F64FCCF6E11",
        "3F84EC4E-DAE8-4AC2-A064-28D6D326D923",
        "3EA2C75F-2891-4B66-B9F3-B257113C35FB",
        "D6A3C714-CF67-469B-8581-B8F9E77CA4F0",
        "22D2A3FE-F262-4349-BDE3-57BC3F37F67D",
        "8A7DF0AC-51D9-4FF7-8631-81EF071AE49B",
        "FD5DD520-B1EB-4C3E-803D-600CC2E90013",
        "47F5450E-3475-47B8-99E6-E6381F61153F",
        "4EFE3A2C-9182-495C-96BC-21C557C8868C",
        "11715571-DC15-44FC-89B3-458C3C7489ED",
        "B50A18F0-7A89-473F-BF2D-8131E3711FED",
        "B48D1341-CA24-4BDF-BF8A-4C4A206605F7",
        "7B9BBBFF-EBC3-4CCA-B272-EB97386020A6",
        "215A79FA-178C-401E-9A82-B78A382CA0FB",
        "85D9E46D-E9C4-4E8E-96E6-245092294237",
        "6EAF04A7-A2B7-498F-88EB-B5C1AB754FF6",
        "3735D6F9-6669-40DF-95E5-E2E5C4AD18A3",
        "C07193FB-62A9-4969-8C33-9E28A43D8C85",
        "BB68B56B-128B-4B18-8344-5CFE08FA9914",
        "26D7029E-4586-4596-B9C0-C578CAB642D4",
        "5555A482-D612-4BA2-A8D9-EA26B81E7B87",
        "036A3927-11A9-44E7-B723-6D14FF5A1E1C",
        "DAAF23C6-7B3B-409C-95FC-56892C9CC044",
        "76988B9B-BC90-448F-B086-C0594B91B5A2",
        "77CD2C34-F0F3-4036-9ADC-B1C1E9E41421",
        "C30C38DD-2DEA-457B-8832-9E52DC858C2A",
        "261428F8-ABF9-4AA0-B30C-632144132092",
        "F79E096C-B0DD-443B-AD91-DA326BFFE35D",
        "34FCA404-8E4D-49C3-89CA-5B715C67BE7C",
        "D09E705D-C547-4F1E-88F2-A9B52027236D",
        "04E1BFEC-D902-4C4B-87B7-05218530724B",
        "5B6752DB-20B8-4D50-870F-8378E17EC5E0",
        "A21B47C1-B278-4D1F-99A5-63AFD581361C",
        "99A71D4E-15AC-41C7-B6AB-08438B2812A7",
        "D2FCE9E4-9C2C-41B6-B1F7-500C44DC829F",
        "0DD2D3F9-7019-4D86-AC8E-C521498B2976",
        "A532AF33-98BD-44E0-9127-3D7E8F2A2297",
        "B76F0A12-B8D1-4CFC-B645-AB0E4F0BF0CA",
        "D8F65B05-2F5B-4BC8-8803-19706B728BB3",
        "A4C4B260-B9C5-4461-AFA9-8A15B1964076",
        "B5A96522-E0EA-4BED-B169-F9F71134E259",
        "F0A28AA5-C8B6-45D4-B7B8-3BD67CC3780E",
        "74B79A43-AAE7-462C-9273-0FB37C867CD0",
        "52F107B0-A252-4115-8E51-A8A9F62FD8F8",
        "D09CC91C-FB95-42D5-B51B-E3740FE8E8AB",
        "8E2E8B94-4435-4EFE-AA2B-07D4B6C367B1",
        "FEDEDDCE-11D9-4059-843E-C33536B6DD1C",
        "E4BAAF63-9538-4FAD-8420-77C232BC66A6",
        "DCF462D3-E340-49EC-8484-9E8F3C7F3091",
        "54CB93CB-6E55-4FFB-B391-A096C8952121",
        "080C5CB5-F974-41ED-BA73-E0F94D47665E",
        "07ABF524-9F23-49A1-B313-B6B13B567C17",
        "D34A7325-D6DA-4373-BB17-20E0DD0CF3A6",
        "E10FD7DE-E622-40B5-AE97-08D05A567025",
        "22854F16-26BA-4145-98AC-60582F4451FA",
        "B3F5F33D-5C8A-4CDC-BD8B-A445281D9639",
        "AA90B893-039A-4670-980D-D8ED4464FB5A",
        "2828D2AE-378B-43B8-A55F-703927E6EB92",
        "89066069-72D4-4778-B908-1CD72EBF3E07",
        "513C4559-9A75-4638-890C-3AC98919E94B",
        "3DB3BED0-C4F3-4995-BE5A-A6F552025AA7",
        "FA2637F4-9D2F-4D67-B31A-544EA5BBA579",
        "8F19B10A-85D7-4686-8A88-C283FF972A86",
        "286B0F6A-314E-457E-87AB-C39C7749EF03",
        "8975189C-CDA7-49D1-BA63-EC97D73EB2C3",
        "B1528377-341B-47DA-883A-DCC9CD16478D",
        "57049E3A-7EB0-402B-BB51-17AE7529A9F7",
        "97A5F413-74F9-4C96-ACC8-F44BDC2CE89F",
        "72F9B7B2-9DCE-4ECC-B800-8820B674EE8A",
        "C21990FF-52C4-4CAE-8B28-6D801E3D2E34",
        "28402F5C-4AE0-4313-BE62-18873E0DD862",
        "6AE37788-2764-4844-97A2-9220903B923B",
        "80AAA1CC-86B2-4F59-ACDC-02ED3F26535D",
        "CF7BAFB6-20FE-4DED-9224-A6E4956DDFAF",
        "07D11D00-2CAA-4E1F-B51F-83077944A358",
        "2C9454F8-9920-4DD6-A7B3-38FAAAF7F096",
        "412AC1EA-85DC-4344-8D08-AC04108DFFA8",
        "2D212BA2-7628-4DF6-BED9-FD84A624F504",
        "080BDCE7-7B26-44C7-8055-38A09EC78C1B",
        "7A9C05B1-2821-442F-AB9A-B76D22CB5F3A",
        "B00350A6-9A34-44BC-B711-75C3E8DB4F26",
        "CC5DF3CB-541D-4334-9CC9-A823207A3174",
        "6103C55C-A587-4213-8C8A-32AF280C509B",
        "8E24F3C3-6B5E-493D-86AA-EA4F23DDBAF7",
        "3936126B-F4E6-472E-93C5-1DB6AE3A7724",
        "0C904905-4D35-4F71-8D35-727D2224C965",
        "7F2C2D7B-CDBB-44D0-9A14-DA03BDAF4F8B",
        "4C492B22-BC9E-4B25-9C3B-F2730891231E",
        "37C0165A-E369-4439-91AB-700282083A77",
        "F5B0267E-71B1-413E-9B96-CB618A1BB998",
        "113C6265-CDED-41D4-AE04-7AC8628A560F",
        "B7AD8D64-A361-46BE-B369-8909CB39E848",
        "DFD43725-B044-4192-8C2E-18189772BC53",
        "7C607D59-2FAA-4EA6-A507-131126A2DCC8",
        "B803BB3C-C800-48F1-85AA-EF9D98E8E770",
        "B53DEBC5-1B71-47CA-A90A-246807B3A4A3",
        "F995B9D7-5C6D-4D3B-868F-16CF813613BE",
        "64D8EE92-A515-4F61-99E2-A1F0424E1A8D",
        "2EE3A214-AB95-429E-ACD4-07226B58B9AF",
        "CEBC499D-E679-4A78-9A53-3404B0641A24",
        "21900E82-8CE1-4313-A3BB-155586197DD1",
        "548ED1AD-DA59-4BA3-8B16-94D9FA0FFCF3",
        "E5162205-AE0F-4196-ABA1-EB118CD636AE",
        "3CA69BAB-2BF9-419A-A3DA-516C20677DDE",
        "734952DC-8955-4AB1-89ED-1856847EEEB0",
        "19363101-5F4E-4722-B5B8-C212D3E92327",
        "F564F874-B984-4709-8EC6-E7DD268178BD",
        "7453F4CA-77AC-438E-9835-A1DCBB1372AB",
        "E4CD6DC0-B565-459F-A944-BE05F75CB445",
        "EFF91ACA-B810-453A-BCCB-0BE29FBC4C7E",
        "F6C36CE8-C4BE-4213-8D36-2BC4160476B2",
        "0417BE7A-30D4-47F2-89CD-49CB9F2CB2AA",
        "AEE7F614-D223-4654-A9E5-D7CCD507AD44",
        "86E8ED9B-FF49-4DF3-8C49-2CC0475174DD",
        "7F1643B2-5EF5-4CBC-BE15-FEAC0E28A502",
        "28252E42-A9F4-4C1D-B04C-2B878A362429",
        "74A94A94-63E1-4F72-A0C4-8281E6E1115C",
        "020D2F02-D4BD-431A-839A-06CF9DFE5C18",
        "5B0B716A-CF0B-4EA8-9AB6-449A3754D40A",
        "0C1CA69D-4E59-4BBD-89E0-E6860C440833",
        "AB287DCE-F4C0-41CF-AE5C-68E004AC5B93",
        "20ED97EE-1C6F-4026-BA31-990A8F008602",
        "2A03C6ED-9630-432F-87D0-588EFD6D0CF4",
        "9113AD40-ED67-4973-B43A-8C62C4B89EA3",
        "8C195DBE-C989-4067-9AE2-252C1C2148F0",
        "8EFEAD6E-8609-48A8-B55D-738937FF0266",
        "DEE573D0-5880-4BF1-9440-AEB02FC67435",
        "463FE2DE-1014-4E2E-9E9B-0CCE3C05EBE2",
        "7CDF4CF5-49BB-4215-A829-4C1BC9B92D2F",
        "6CE8EE62-31B5-434F-8CCF-985C9E6656D5",
        "7801E3B9-D59B-43ED-B0D9-3696C95B354F",
        "D3F841EA-0599-41B8-92FD-3B42CEE7A2C6",
        "CE7C56FA-7AAB-4EDB-BBAF-12FBDD559D5B",
        "F4371924-2738-4BD8-B5A6-96DA2AFA34B6",
        "058A8FF1-3545-4900-BCDD-480400A3BBAB",
        "CCF79026-994E-4AD7-9CAE-F0B377B53F90",
        "4B809A9C-97A1-4826-8D84-5C54F6A0DBEE",
        "E0670EE9-DF25-4FC8-B427-DF0A74C5B32D",
        "5E9B0F71-0BAF-4474-A732-C74C8153345E",
        "D74B0419-8714-4925-B08B-1BEAE67E5EC4",
        "4601FB51-65A2-475D-8630-2071CB1B2F09",
        "CF622A7C-B42E-4766-A980-DA594ABF488C",
        "A04B9468-CC3D-4983-A3D7-2CE0740CCC28",
        "2C310232-432F-4D92-8D48-A40EC3A72EF0",
        "AF257BEF-2076-4784-B5AB-9887E20AD6DF",
        "3466566E-5A64-4468-A8AA-556D34F5A3E0",
        "A4888214-4371-490B-AC49-09380FFD06FF",
        "89B4C1A8-411B-4716-8EC5-00680F0C720D",
        "5CD4B2B1-CFFE-4CB5-9C1C-DB34BBAD3CEE",
        "C821CFB2-C0DE-419B-8F0E-DC0E7E185947",
        "6C099E2B-FB5A-4499-98BD-9088E3C1D1CA",
        "93F70C9D-2F78-45D4-959D-913393E71C00",
        "AF95E6A1-1E7F-49F2-9340-910986AB3730",

        "765C92CD-7670-41C9-8120-5FC16CE48BE8",
        "7FE945DD-3D70-41EE-AE4D-8BDE123EFAE7",
        "8F131E7C-34EC-4191-A0A5-E6544230078C",
        "24B1492F-4CAD-4643-83D8-96E7B618764C",
        "75CB8CE3-DDA0-47BC-B2E8-42E4620BE3E8",
        "89DA856D-E7E6-4936-A7EE-327EACC8C78F",
        "5BE237B9-6AD1-4A8F-8D5D-B0BF12D25428",
        "77973060-9413-40FE-B555-FDB9F2B1843D",
        "D0206B32-FCB7-4920-B654-6BD7C7D55DB0",
        "DE361B00-534F-48B6-AD66-899CC64DDE20",
        "65761F62-140B-4F99-97E8-06DF0DFABCD7",
        "B185E304-99FD-4A05-B692-D21789561C28",
        "B2DDD5B1-5448-4BDF-B2A6-4B415B020895",
        "038A4277-F5C6-4BCE-953C-009B36BEA65E",
        "3ADD415A-5FAF-4BF1-ABED-4516D50B26F3",
        "D5A9E971-1F2B-4BBE-A82D-0B2B5E1AE4BA",
        "B1769824-1C6E-47FE-9A8F-3F4761A4A512",
        "A3A756DD-AF46-4D7E-A0E5-E9FE5A64ACC3",
        "6AA4C94E-B1F5-43EB-8226-52E8CBF2BC53",
        "FDA46D63-BD3F-4AC9-864F-EBF3F9206B39",
        "31FFF201-ED0A-4BE2-91CD-DCDE235D3F31",
        "F3DEBDA7-DE41-448E-A23C-D80E3F39C7A9",
        "8E58C2C8-D314-4482-BA51-3904F2192209",
        "C67C0D68-8AA8-4ECD-8372-A8A4BF2CE954",
        "1F18A086-23BF-4BAD-8835-B953FE0EA2EA",
        "BC9D73FC-23B6-4490-A73C-06208396DF9F",
        "4A2F3626-A1A1-470B-B4CB-99C8EAFCF28F",
        "A0D5B019-7A1F-49AD-8D45-AE2B746BA24B",
        "6F6AF96A-91CE-45F6-BC06-A648D1CF4589",
        "88365783-729E-4CEA-B666-93FA70875D12",
        "6E162560-743A-4520-8021-C3BA73FCC370",
        "C19D49C9-2828-4DAB-ACFF-16C8384526B5",
        "F6C341A6-ED85-493E-84B5-4D602C34E29E",
        "9B21D3A9-F1C5-4779-BAD1-641C7EEDB02E",
        "94E5B2B6-F2EE-4BC4-AE45-4CAA666AACC2",
        "CB944452-3D18-47B1-B270-30841D9AB97A",
        "97A032E0-A87C-4D7C-840F-1B0E81E5AD48",
        "9AE5C1EA-D370-4218-9CC3-2EB722A35CFB",
        "DE804C8F-8BA4-4A53-B2B2-939177F0B25A",
        "5DDA02D1-15F5-4BB6-98E2-CD9354474D25",
        "C6F23870-C4DF-4D57-BEFA-6AD54E641EFD",
        "D1F1CDC9-0523-49D0-93C7-022CC4B2E7F1",
        "1A94E20E-180A-4632-B575-763B71256C35",
        "BD79051C-3809-408E-9465-84906421CF12",
        "C27F8F30-6862-42A9-813F-B51C93667926",
        "7000715F-C70C-45F2-9094-DDEF6FC68DD1",
        "358B2C81-E491-498A-ABDA-3D74272DE7CB",
        "07AEF953-105F-43D0-872F-DE84C2B93B93",
        "56CF970E-D1A9-44B9-99E8-284952F303BD",
        "34C35175-E5E2-4D34-B77A-736769CBB218",
        "D1D3000C-EEF1-44C8-ACF8-7AE044F20D16",
        "E346F6C6-4CB8-4072-B946-E7014D3D6848",
        "733B41C8-1BAD-4B93-A1C4-816CBD34D5B6",
        "FBA61521-C3A2-4293-A221-975F399D3A4A",
        "8435FC73-9D90-420D-A8D5-3063AE2DFAFE",
        "7C4C9EC7-6C2F-47CD-AB66-A8A5E4297D3F",
        "46F64B5B-053A-44AF-8976-B490D2F06C71",
        "15D012C1-927D-4790-9CEE-43FB709AD99F",
        "4B2D1813-F1CC-4EF5-9BE7-995D02FC2135",
        "4C8A44EA-E94B-4618-B1A9-49E50542A4DF",
        "8DDA9177-2F10-49C3-9BC2-89DA67E080AA",
        "4591CAFB-ED3F-4BE9-B63C-2AEE6860B37A",
        "5E7D60DF-DF1E-420B-B726-6F6B386441C0",
        "5D742ED1-0B20-4BAA-ABBD-BD83F47F91DF",
        "A690B2BA-DDA0-406F-B560-D801FE7E5F4B",
        "A92BC478-F4FD-452F-8A62-9985217CE655",
        "682DCA29-829C-46E3-A490-426DB04CB03D",
        "3DE681D8-84A4-4EA6-B3FB-829D4F4CB403",
        "F2A96794-6F5D-4627-9D02-6204A10AD59C",
        "0EA876A9-4863-4F3D-8964-133798C4EB60",
        "77E16F62-3DD7-4C34-BC72-444E3774D1D0",
        "BD0D1104-C3E9-48F4-9F41-BB4FBCE60C7B",
        "8DB60C99-40A7-4E6E-B1DF-32F34DC09E71",
        "6B84C28C-C0CC-4B06-9FA1-F4A9561DD310",
        "73E15016-3449-4EAD-A97A-F8CA72E31337",
        "3EE309BC-9365-4CCC-A266-D8E8A87AC442",
        "1C30B2FF-A265-4B1F-BBD9-BCD6885F30BE",
        "D8D8FCE9-7BE8-409B-8DE4-0FCCBDE843AD",
        "5AEE5474-9E47-4137-B7CD-E17B04A5EAF5",
        "A66A2A97-B4A3-4A4B-98D2-25712ED57BB1",
        "E1058E59-45CD-4882-9156-A781FF49166B",
        "B494B921-A09A-4A3D-81C3-96B4596785AE",
        "83808CF2-E409-4C23-930C-09D611BD2348",
        "A0F48855-F519-4760-8D31-B979AEBA13C0",
        "647525E9-9C75-4280-9E34-5A307304427F",
        "AECF4C15-3CF7-45F7-B099-17707B44CC69",
        "3B8D9FCE-A7B8-4D73-877A-B912C11BF378",
        "C7D97722-88AB-46FF-97F6-3CFBB0C83DEE",
        "6F617038-A6CA-434B-9AC1-9616DE7359BF",
        "6C6C0A33-9ED1-433B-A8B7-467C3D532A17",
        "05CC1E23-80CF-4857-87C6-627D86361B25",
        "60819DE1-06DD-4A5D-8BC2-C30156CCDA73",
        "AF2EF565-104B-46E6-85B0-A16856C15A68",
        "B09BA793-3D8C-40E8-AD2D-F1A0E55737D9",
        "F0AEFFDA-FAFE-40A9-A109-9A00D2FB1BC4",
        "B1E73BD1-A629-44C0-BE17-0F8C472A2E99",
        "F3550406-ADB6-4582-9D1B-AFF64DE4657F",
        "0C25B84D-27A8-4F88-8E89-BD18BE6FC678",
        "928C8CB7-B215-4FCE-98C4-815C74F42A1A",
        "5D788281-4625-4DF6-B46D-2319DEBA47BC",
        "646E57CE-C7FD-4B3C-96DE-A4F553445FBA",
        "E361B4E0-4851-4AAA-80F0-46077FACDA11",
        "5A5229DF-59AA-4C92-A0DB-40ED83583D31",
        "1FDA1295-CC57-4F65-963D-355A60CC0E3D",
        "9219AFF1-6C02-4D20-A841-FCB7F5474809",
        "260A2057-CEC3-47D7-BA86-1D2287807D57",
        "65112409-431C-4267-B8FA-1EC1B5D9C238",
        "D4D31D99-180F-4B20-9D6C-D64485EE2070",
        "793C6738-7F03-4D69-833F-FBFC66CD91B6",
        "95132253-092F-4626-B7BE-CDD117948922",
        "DE786E4C-D262-4D92-A8FC-ABC3B29FAE9E",
        "611AED0C-F9DA-4C46-BF54-007870359421",
        "BB0538DF-7C00-4B4B-BA35-A882AEBFC249",
        "A3565925-46F0-4A10-8510-1B318D0B2A7B",
        "323A34B9-3D3F-45AC-B4F2-DDC82D201793",
        "063CF63F-C921-4F01-A75B-E681AC8EAA73",
        "D6CC66BF-84B0-446F-A121-83A1BA6C9FF9",
        "6167EBCB-6657-4D92-ABD3-0C0C178ADC0B",
        "98C0B2DF-9292-4693-A15C-506B03CA466A",
        "CC0BF154-383B-46BF-AF3A-168684B2E048",
        "3F8D250F-7EE2-413D-A865-CF3CC60CEEC6",
        "8CC2856C-4084-4709-825E-E10CD4D79189",
        "0BA901CE-76BF-4DDD-A774-C3E5369B2077",
        "128605CD-7D7A-44EF-A366-0357654BF5D0",
        "6AB3520D-45DF-4C6F-A450-A5B7A4F4B0E6",
        "81C02DE2-20BC-40D0-A2F2-3EE13136783E",
        "D6692F70-9802-4ED7-A73D-00000B0B9B20",
        "CB35D30A-4181-4B9A-B43A-768688D69256",
        "525299AB-2503-48C5-9BDB-A341946030D0",
        "0B103CE2-DABB-4653-9A25-6F6F1A2A80B2",
        "01891A09-718E-486C-84EF-2D3FB122031B",
        "58DDCF33-C42C-4216-A69B-6F2F7ADC02C9",
        "7DBB2F23-8D2E-4B10-B6BE-29F9441B4702",
        "273CDDD5-B0A3-4FDE-A71A-F9EA287FDAAA",
        "B550B18C-D315-4179-AE6D-DB7D7900FD1C",
        "FEB077D7-14C9-42FC-9686-24117C60ACA7",
        "8F27465D-038E-4583-B1D3-6792684BA1DD",
        "A3DC7315-B1BC-425B-8C2D-A40FE247C1E8",
        "82255079-80DE-45C5-81FE-9B5F4108DD97",
        "6CC3151D-3CDB-402E-A53F-36C76D1027C8",
        "53FB0A5F-A69B-4912-948D-9C18244E4728",
        "66D1A7E7-3548-4797-B76A-BCADBB7D6842",
        "395878AE-616E-438C-B2A6-FBFD3517BA6F",
        "7EEAB3C9-6893-4AB7-BD92-F70CE5D9CA5F",
        "55434A19-2395-4474-A6C6-D33B7F812DBF",
        "1014A262-FA6B-4A26-A8A2-FAF41D39E8B9",
        "05444960-1A4B-463E-87EB-B2B5C14D6529",
        "FDE146F5-66C7-4947-B749-1310E541DA9B",
        "BFB3690D-A63D-41A6-87B1-4F5EA857D857",
        "DA1688B4-06A4-4EFA-B1AD-EE7D23FFB510",
        "59F6D602-E604-4FC5-A62A-3B16351EDD5B",
        "39DAFB56-DB34-4D05-B42E-61DE6EFE1B8A",
        "F33614A5-FE99-4A5B-B79F-5AACE48098F7",
        "AC66847F-12E1-44A2-B272-1F047E906493",
        "7D61C733-24BF-438E-B397-27D75320615B",
        "F2DA4A60-AE4C-4B12-BA23-66FEF7229FC0",
        "BE1B4E8E-7150-414F-8E8A-8A6A433170DF",
        "B3240E10-76E4-412E-90B2-36CB599298BD",
        "C1E982BF-7A2E-4164-ADBE-54C43BF2BF6C",
        "ED5BBA56-2E0B-413C-A06B-7550BE53D5B9",
        "9F9E9DE3-DE45-466F-BAAD-F9620EAC0556",
        "37622EF6-547D-45F7-8EDB-B9ED5685DB91",
        "4DD736B5-3B5C-4E11-9176-E8211214480E",
        "0991FAAB-D9B7-4F7E-89E4-F7FF0F2095D2",
        "134F759F-9955-49C0-851C-E3C260B9E748",
        "D13B8B98-7E7C-49FB-A57A-258F00D33CD3",
        "E40F58E6-F6DC-4762-B491-AE5BE1FD8D58",
        "591E1A36-24B3-4EE8-BECB-7C9D09BB1C0D",
        "D42F70DA-0ED1-413F-B652-56B6F12D0307",
        "D95D5025-5D9D-404C-9634-55EFA9349E7F",
        "E196F612-E3B0-4BE4-BCB2-4CCF9F91ADDF",
        "FEE7A406-939E-4DB4-ABA5-A174AAF679B6",
        "9EC483DE-EF7E-4D00-A393-C91203B23B10",
        "9B4EFCA0-3777-4246-A752-53C36E89B02A",
        "0FE5BAE0-BCE0-4AF4-B5D6-8F48A5D253F2",
        "6E2261F0-8EEC-4516-85F7-D60AD5F04969",
        "DD32AB48-A178-44A3-A082-019CF33D864D",
        "0D21024C-A7EC-4828-AC6E-19DBC793033E",
        "58E20990-7B27-4A89-B56D-145B7F710A6C",
        "951AC086-409F-4610-A396-D6CEED84580C",
        "831B9822-BF06-4833-A7C3-1A51BE9BC4A9",
        "5BFF9B01-221F-4E3B-84CF-AB5020C67F27",
        "A9A54F19-ABD4-4FC4-B93B-055D1688019C",
        "D8CD1547-B092-4CA1-B1CE-30C463F6CD66",
        "70B27925-4624-4E40-911C-2692C508596E",
        "12E08139-E22A-4A74-84CF-4C0679F07B0C",
        "A7C02BFD-464F-4A31-B775-C77DA4C01008",
        "096E847D-422C-4AF3-B8A8-BDE6176AEEBC",

        "493283E4-16E9-4217-B8A6-29F98104F36D",
        "BEE50E90-3D09-44FD-B247-9BD443121C5E",
        "849C0965-7FBE-4E0B-8445-8EF9E20822F5",
        "A0592C59-3E2E-4720-A189-E5B6D5997456",
        "870B61A1-B472-43FB-9074-DAA42EACD8F5",
        "90DEBA38-8C96-40AA-B5FB-1031CE1285B9",
        "C3ECEA2C-1E1A-42F9-88C2-F3A78D751B63",
        "026CA6B0-BB45-4727-8453-343979952DF6",
        "1B182288-A6F5-46D8-A89A-4949DF7412BE",
        "71D954D9-D156-40AC-9C46-5858F97D811C",
        "683C2DE8-BD60-4FF0-A00D-E5C5636553CA",
        "BCB14366-CC6E-4EDB-B13B-227079A1B309",
        "E8AFC411-0834-4B68-8385-9F926C126B9F",
        "CC571A63-3AB1-4143-965E-44A511F91786",
        "467E88A6-B990-4A5B-AB99-E12A8EEBFC62",
        "DD86B522-902D-4F27-85E5-13A8CB27D095",
        "C1EA0B4F-1753-415F-A090-CEA8B8CA1859",
        "3EE309BC-9365-4CCC-A266-D8E8A87AC442",
        "1C30B2FF-A265-4B1F-BBD9-BCD6885F30BE",
        "D8D8FCE9-7BE8-409B-8DE4-0FCCBDE843AD",
        "5AEE5474-9E47-4137-B7CD-E17B04A5EAF5",
        "A66A2A97-B4A3-4A4B-98D2-25712ED57BB1",
        "E1058E59-45CD-4882-9156-A781FF49166B",
        "B494B921-A09A-4A3D-81C3-96B4596785AE",
        "83808CF2-E409-4C23-930C-09D611BD2348",
        "A0F48855-F519-4760-8D31-B979AEBA13C0",
        "647525E9-9C75-4280-9E34-5A307304427F",
        "AECF4C15-3CF7-45F7-B099-17707B44CC69",
        "3B8D9FCE-A7B8-4D73-877A-B912C11BF378",
        "C7D97722-88AB-46FF-97F6-3CFBB0C83DEE",
        "6F617038-A6CA-434B-9AC1-9616DE7359BF",
        "6C6C0A33-9ED1-433B-A8B7-467C3D532A17",
        "05CC1E23-80CF-4857-87C6-627D86361B25",
        "60819DE1-06DD-4A5D-8BC2-C30156CCDA73",

    ];

    return idpvAcceptList.findIndex(x => x === idpv) > -1;
}

export const redirectIDPVsNew3DSPiloto = (idpv: string) => {
    if (idpv === undefined || idpv === null) return false;
    
    var idpvAcceptList = [        
        // PROD
        "F72B2765-3B67-4CF1-8A79-F8E69B18FB5E",
        "33B69ABE-9446-445D-9E69-B25AAEDABFD4",
        "63079F7C-9D90-405C-87F4-2C6FB3146F99",
        "B2EA95F8-B5F1-40B9-B06F-2BCC138E3A9D",
        "3300D116-9279-41BA-B631-2D4E0439565B",
        "52544941-0E85-4520-8FAC-4703D460CCED",
        "63DE1EED-4154-4AC0-A3AB-407F04DC084F",
        "90E260D4-6518-45DF-A2BE-6CEF76B74B07",
        "174DD4DF-F588-4ECB-9BE8-54EC72E8D6FD",
        "4099EB16-5ECA-4838-BC6E-D5CE6FB255FE",
        "A98B2582-0731-4D4C-850F-76D3565857CC",
        "9FF852DE-1D73-4431-B4C9-630EBF04373A",
        "C19C75C4-D9F4-4111-B32D-CC968091C4D6",
        "14185A09-8011-4499-B910-DA5C8934FC4D",
        "778EBBAA-D48F-4699-BE57-545D2531E87C",
        "FC0D34BB-27B8-46AB-BA2D-9C3A7D9A9719",
        "51B4D790-F18F-4D12-AC8C-729F936F38B1",
        "F71E41FD-8349-4325-82B5-0CB2758B8E18",
        "B4213B4D-1BC5-4850-9F2D-9E9C1072C5B9",
        "5683B01D-0C43-4FFC-89BB-D13EEE2C04BB",
        "297CDA6F-9094-4AAC-A587-7AC9EF20F1CE",
        "EE411EBA-23D7-44BE-B1D9-7EB9BD98521A",
        "A24B1B82-75D5-4855-82FA-9C2BC02213FC",
        "1EE514E7-6E39-4AFF-B3CC-00B1A186E225",
        "B1B4DC53-11CD-47D4-8E64-A2E50A6DBE4C",
        "148C5B3C-C14F-47E2-93A7-DC742A87C6A3",
        "DFE3F134-44C4-495D-A4E9-18F14A9A8A56",
        "A62AE1EE-19FA-4DB3-9E44-0CEE66DAF7A1",
        "9434FEFC-6F64-4377-81F7-C313A783B31B",
        "3E51B46D-3F42-403C-ACB2-EDDB479BEA92",
        "120C09AF-0902-44AB-8840-4FF8DC1B480B",
        "A76AFB51-08C8-4BDA-8177-051C71D725CF",
        "0508E2B2-B9F1-4ECF-A7F7-70EC52E75E3F",
        "B1CDFF00-87F4-478C-A739-D31947D3EB91",
        "5D234D2D-CBF0-4F34-8EBE-41C59F3A9CD6",
        "EA296101-6D65-4D88-BB89-BF86D3264D62",
        "31AA6001-51B8-45DF-9650-C983D42A82B1",
        "6C366872-891D-4137-B945-B7FE047454B3",
        "976EF0E0-344B-41A2-9706-4CE28E71FC20",
        "D1781F22-FF1E-4E0F-9FF9-D7D4906F41D9",
        "3B0A42D3-D3BB-4525-9FD2-86AE1C270A13",
        "7608B778-5B34-4D71-A9EA-45F8865093CA",
        "DB4D3128-20D1-4775-9358-302FFB8EABBA",
        "6A37D8FF-8368-43FF-AED5-552B36AB45F5",
        "F6E67C84-B55C-4CE6-AC55-4D8A0D23D8BE",
        "729711C4-A4B8-4621-B559-58AC2C988C93",
        "A2CE37B4-8A75-4DB3-B8F9-404DB5664A2A",
        "AF500D07-D2C8-477B-B857-E00ECDE36D39",
        "FCE6C974-D594-4E4C-B041-F7C45D67DEAB",
        "AC685AD9-2F37-42BB-8A50-79BE17AA4691",
        "9F8D072F-CDDC-41BF-97AC-92120AF84688",
        "49699904-0F02-4E98-AA83-887592FDF818",
        "B5E7F9D9-7D1F-4E6A-B70C-E41EEC752295",
        "63F48EBC-F10D-4779-821A-79C8DFAF718A",
        "AC654E23-9518-41F0-A245-79620B544491",
        "F420C3EC-85D2-4D79-AE87-3D933EC4E208",
        "21063467-24F8-4650-8F40-9883658798C8",
        "343A117A-BFC7-46C9-92FD-E458251AA5CB",
        "2AFAA1E0-525B-475D-B409-5F2519B10517",
        "D5160EF9-1030-4C1D-B215-114BFA9EC054",
        "72943866-385E-4B92-B885-69E37533DA02",
        "779776B0-CF19-4A06-88F9-21E0225915A8",
        "B19C8E02-B14A-4ADF-8C0F-B4AE42BE016D",
        "CD4DBAC8-4B68-45B4-9238-9CF4F3BE8280",
        "7EDFB63B-3CCB-4E00-A2F4-60494AA8AC5F",
        "E1FF0C4F-B92A-421F-B971-85F24ECDF19D",
        "B9132525-57FB-43F2-94AB-475D0B85074C",
        "FE15A43C-D6FB-4618-B785-CC0E764E5A3D",
        "DB35BBFD-87BE-4E9B-BE4B-B62A6D3BF767",
        "403A5EF8-1CE7-4CAB-AE75-FB1578522FC4",
        "6E530F2C-F561-46F3-AF7B-D1D4B8618475",
        "D84AAFBE-CFC5-43F2-8FD9-8F76C812203F",
        "6AB12F0A-E39E-42D0-9A85-EFB49FD52D02",
        "E7765CA3-E384-4B3E-B7B0-DC31D7D87FD1",
        "CF2646BF-52CB-4A9B-8695-C79D82DE6CBA",
        "BAEDA4F9-0487-48BC-ACAC-08AA423E28E8",
        "EE6CE75C-D7B5-4DBC-920E-F256D0D03CC7",
        "4A03267A-DFC3-46E1-B846-E68A6BC1CB49",
        "7834C449-20D8-43EF-8519-6E431C99FDCE",
        "A12E40E3-E180-44E0-B601-21E68F018FBC",
        "307B1A10-B650-452E-A882-D466D132F2C8",
        "6C7C80C0-4ACD-4687-90CC-E56B4F4FDEA4",
        "BF1849C8-4002-4257-8CD1-F46B6A13B0E9",
        "4FDABAAE-2631-45E4-8B9A-8F8D69E09A1B",
        "1B2EE563-DDA7-4092-8CF4-5F4837C3107A",
        "B4AACDBE-2CBF-47C5-91A5-E4A2D2ECCBC4",
        "03AC99FA-B269-475F-88B4-2BD291DAD8FE",
        "E90BCC96-ACDF-4F6E-9588-0346E5064F70",
        "C06FC95C-ECAB-448C-80C6-6488449F337E",
        "432FA938-9E0B-4AF0-9744-DCB3AC34417B",
        "D5D51006-01BE-4392-B0BE-EC57265241E9",
        "E32FB69D-40A5-419C-B479-A2544D1D498E",
        "EEA9D595-DF8C-4857-A44B-9AB349A4B2E4",
        "7B805F38-5602-4569-871F-7B24897F7CB7",
        "C3A38E95-30CA-4D86-8938-8466168B2013",
        "5FFF72CB-4ADA-4F76-820C-45FF64472D95",
        "E5A3F521-F119-4362-9F72-1150E91E5455",
        "63B9059C-294A-4899-820E-880E5DCE7671",
        "EBADF2C1-10C8-48B8-85EC-E7A082F63353",
        "E281C34C-DE6B-49B0-9A1A-DB192C6C0DE9",
        "9A6B529F-CAA9-4727-A91E-D7A578EFDE04",
        "EF1B1EBF-1284-4EAA-8D2D-7453ADDDDCBB",
        "570D5BE0-AA5C-4AB3-81A5-3670A665EF37",
        "69424212-40D8-498A-95BC-86F0072320CE",
        "EFFFC0F8-C689-483F-96DA-231E59DD2C87",
        "84DD1130-11EF-4598-BB32-0F8583593196",
        "10367E9B-A23B-4900-8FF1-9614B7813774",
        "A209B487-78E0-4F09-B6E4-D12BEA12B9CD",
        "B08ACAD4-B66C-4CF8-AF62-8755FDB1AE4D",
        "4909C709-D0B6-43BA-A9E9-C2D0F9413BE1",
        "75934F49-BFBD-4F86-AD88-F32A64B68E0C",
        "79072F24-3AD3-4424-9170-0AA629E0FC39",
        "13FE4E57-1CD7-448C-8386-AACE15702484",
        "7236F974-E4D0-4DC0-8CAE-DEF25EF166E8",
        "262347AB-3A40-46A0-821B-306AF1FA300D",
        "DE4A160C-93EB-4879-9E26-7FD5F18DF052",
        "DEA460A9-8BA0-4866-B216-79DCB0948DD8",
        "01DAA67D-6473-4FDC-A833-D7364C56D3FD",
        "E82F2B67-3463-406D-9894-6892C81ED611",
        "CEBDC666-6E50-4ACB-AC66-A12CFC6D02F6",
        "1674E9EE-6140-4793-906A-1C2B844AA55D",
        "9737B66B-EDBA-4D38-8B24-7319AF7DC7DC",
        "1717833A-5E4A-4F27-B7EB-9FDFDB3BB09F",
        "0BB8CA51-E8D2-4C2D-BCA8-45AA849A616F",
        "2F2C4DE3-4109-4A23-9F86-8FA74CEFE95B",
        "B09E14D8-09DE-4CB4-87C9-0D0DB6CD4009",
        "25CBA75A-D32C-45C6-9E54-76DB19F31544",       
    ];
    
    return idpvAcceptList.findIndex(x => x.toUpperCase() === idpv.toUpperCase()) > -1;
}

export function uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
        // eslint-disable-next-line no-mixed-operators
        v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
    });
};
