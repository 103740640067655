import { Modal, Row, Col } from "react-bootstrap";
import { ReactComponent as IconFechar } from "../assets/icons/icon-close.svg";

export const ModalTermosComponenteSergipe = (props: any) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen
    >
      <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <h1 className="text-uppercase text-center modal-title h4 mb-3">
              Termos e Condições
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <iframe
              src="https://adesao.cartaodetodos.com.br/assets/terms/checkout-termos-condicoes-sergipe.pdf"
              width="100%"
              height="1000px"
              title="Termos e Condições Sergipe"
              frameBorder="0"
              style={{ border: "none" }}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
