import { FiliacaoService, MarketingConversionService } from "../../servicos";
import { useLocation, useNavigate } from "react-router-dom";
import { getCardType, getFbPixelDebug, hash, setEventPageView } from "../../utils/utils";
import { Configuration } from "../../modelos";
import React, { useContext, useEffect, useState } from "react";
import { CheckoutDTO, StepDTO } from "../../dto";
import { AnalyticsService } from "../../servicos/analyticsService";
import { CheckoutContext } from "../../context/checkout.provider";
import { DataLayerError, DatalayerPurchase } from "../../utils/datalayer";

export const Logic = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // ////////////////// DADOS PARA TESTES/////////////////////
    // const configuration = require("../inicio/configuration.json") as Configuration;
    // const dto: CheckoutDTO = {
    //     nome: "TESTE Checkout Buetto",
    //     cpf: "69917153870",
    //     email: "69917153870@gmail.com",
    //     telefone: "16987987987",
    //     termos: true,
    //     termosSmsEmail: true,
    //     cep: "35162624",
    //     franquiaId: 46,
    //     rua: "Rua A",
    //     numero: "564",
    //     complemento: "",
    //     bairro: "Primeiro de Maio",
    //     cidade: "Ipatinga",
    //     estado: "MG",
    //     voucher: '',
    //     linkAfiliado: false,
    //     id_promotor_lead: "",
    //     ga_codigo: "e87cc82b-6f31-486c-921d-21c16548c780",
    //     data_nascimento: "",
    //     maior_dezoito: true,
    //     addRefuturiza: false,
    //     etapalead: "Pagamento",
    //     tipo_de_vendedor: undefined,
    //     campanha_publicitaria: undefined,
    //     pagamento: undefined,
    //     token: undefined,
    //     black_friday_2022: undefined,
    //     test: false,
    //     adesao: undefined,
    //     mensalidade: undefined,
    //     total: undefined,
    //     fechou_checkout: undefined
    // };
    // const total = 59.4; 
    // ////////////////// DADOS PARA TESTES/////////////////////

    const configuration: Configuration = location.state !== null && (location.state as any).configuration !== null && (location.state as any).configuration !== undefined ? (location.state as any).configuration : new Configuration();
    const dto: CheckoutDTO = location.state !== null && (location.state as any).dto !== null && (location.state as any).dto !== undefined ? (location.state as any).dto : new CheckoutDTO();
    const total: number = location.state !== null && (location.state as any).total !== null && (location.state as any).total !== undefined ? (location.state as any).total : 0;

    const { URLCurrent, utm_Source, utm_Campaign, paramsURL } = useContext(CheckoutContext);

    if (location.state === null) {
        window.location.replace("/" + window.location.search);
    }

    const [showPayment, alterarShowPayment] = useState(false);
    const [paymentAttempts, alterarPaymentAttempts] = useState(0);

    const [showError, alterarShowError] = useState(false);
    const [loading, alterarLoading] = useState(false);
    const recaptchaRef = React.useRef<any>();


    useEffect(() => {
        // document.head.innerHTML += configuration.meta!;
        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);
    }, [])

    function getForm() {
        return {
            nome: dto.nome,
            cpf: dto.cpf,
            email: dto.email,
            telefone: dto.telefone,
            termos: dto.termos,
            termosSmsEmail: dto.termosSmsEmail,
            cep: dto.cep,
            franquiaId: dto.franquiaId,
            rua: dto.rua,
            numero: dto.numero,
            complemento: dto.complemento,
            bairro: dto.bairro,
            cidade: dto.cidade,
            estado: dto.estado,
            voucher: dto.voucher,
            linkAfiliado: configuration.linkAfiliado,
            id_promotor_lead: configuration.id_promotor_lead ? configuration.id_promotor_lead : '',
            tipo_de_vendedor: dto.tipo_de_vendedor,
            campanha_publicitaria: configuration.campanha_publicitaria,
            ga_codigo: configuration.ga_codigo,
            data_nascimento: dto.data_nascimento,
            maior_dezoito: dto.maior_dezoito,
        } as CheckoutDTO;
    }

    const handleSubmit = async (event: any, formCartao: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (formCartao) {
            alterarLoading(true);

            dto.pagamento = {
                numeroCartao: formCartao.number,
                nomeCartao: formCartao.holderName,
                codigoSeguranca: formCartao.cvc,
                ano: Number.parseInt(`${new Date().getFullYear().toString().substring(0, 2)}${formCartao.expiryYear}`),
                mes: Number.parseInt(formCartao.expiryMonth),
                criptografiaAdyen: formCartao.cryptData,
                bandeira: getCardType(formCartao.number),
                total: total,
                bankName: formCartao.bankName,
                method: formCartao.method,
            };

            const captchaToken = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'checkout'});
            if (captchaToken) {
                var resp = await FiliacaoService().sendFiliacao(dto, captchaToken) as any;
                if (resp !== undefined && resp.success) {

                    const refuturizaStorage = window.localStorage.getItem("refuturiza");
                    const refuturizaParse = JSON.parse(refuturizaStorage as any);
                    const cashbackEDTStorage = window.localStorage.getItem("cashbackedt");
                    const cashbackEDTParse = JSON.parse(cashbackEDTStorage as any);
                   
                    DatalayerPurchase({
                        cpf: dto.cpf,
                        email: dto.email,
                        phone: dto.telefone,
                        tid: resp.data.tid,
                        total,
                        method_payment: formCartao.method === 'credito' ? 'credit-card' : formCartao.method === 'debito' ? 'debit' : 'pix',
                        voucher: dto.voucher,
                        price: total,             
                        idpv: idpv,
                        isRefuturiza: refuturizaParse,
                        isEnergiaTodos: cashbackEDTParse
                    })

                    // Facebook Conversion API -- Event Purchase
                    var explodeName = dto.nome!.split(/ (.*)/s);
                    const fn = await hash(explodeName[0].toLocaleLowerCase());
                    const ln = await hash(explodeName[1].toLocaleLowerCase());
                    const em = await hash(dto.email!.toLocaleLowerCase());
                    const ph = await hash(dto.telefone!);
                    const ct = await hash(dto.cidade!.toLocaleLowerCase());
                    const st = await hash(dto.estado!.toLocaleLowerCase());
                    const zp = await hash(dto.cep!);
                    const country = await hash('br');
                    var payload: any = {
                        type: "facebook",
                        data: {
                            data: [
                                {
                                    event_name: "Purchase",
                                    event_time: Math.floor(Date.now() / 1000),
                                    user_data: {
                                        fn: fn,
                                        ln: ln,
                                        em: em,
                                        ph: ph,
                                        ct: ct,
                                        st: st,
                                        zp: zp,
                                        country: country,
                                        client_ip_address: sessionStorage.getItem("_ipv4"),
                                        client_user_agent: navigator.userAgent,
                                        fbp: getCookie('_fbp')
                                    },
                                    content_category: "CDT Checkout",
                                    content_name: "Filiação Cartão de Todos",
                                    num_items: 1,
                                    custom_data: {
                                        currency: "BRL",
                                        value: total,
                                        payment_method: "Cartão de Crédito"
                                    }
                                }
                            ]
                        }
                    }
                    if (getFbPixelDebug().has)
                        payload.data.test_event_code = getFbPixelDebug().value;

                    MarketingConversionService().sendData(payload);

                    var successDTO = new StepDTO();

                    successDTO.user = configuration.ga_codigo;
                    successDTO.data = {
                        form: getForm()
                    }
                    // ActionService().sendSuccess(successDTO);

                    AnalyticsService().SendAnalytics(
                        {
                            event: "Purchase",
                            channel: URLCurrent,
                            parameters: JSON.stringify({ 
                                payment_method: formCartao.method === 'credito' ? 'credit-card' : formCartao.method === 'debito' ? 'debit' : 'pix',
                                message:  resp.message ? resp.message : null
                            }),                           
                            source: utm_Source ? utm_Source : null,
                            campaign: utm_Campaign ? utm_Campaign : null,
                            url: window.location.origin,
                            lead: {
                                name: dto.nome,
                                email: dto.email,
                                purchased: resp.success,
                                registrationCode: resp.data.matricula,
                                ctnId: resp.data.idFiliado
                            }
                        }
                    );


                    navigate("/sucesso", { state: { configuration, matricula: resp.data.matricula } });
                }
                else {
                    alterarShowError(true);
                    alterarShowPayment(false);
                    alterarPaymentAttempts(1);
                    dto.pagamento = undefined;

                    DataLayerError(idpv);

                    AnalyticsService().SendAnalytics(
                        {
                            event: "PurchaseError",
                            channel: URLCurrent,
                            parameters: 'Erro ao processar o pagamento, verifique os dados do cartão',
                            source: utm_Source ? utm_Source : null,
                            campaign: utm_Campaign ? utm_Campaign : null,
                            url: window.location.origin,
                            lead: {
                                name: dto.nome,
                                email: dto.email,
                            }
                        }
                    );

                    /**
                     * Quiz Cartão de todos
                     * - Faz o redirecionamento para o quiz de formas de pagamento caso o cartão do cliente seja recusado por qualquer motivo.
                     */
                    // if(paymentAttempts === 1) {
                    //     const formData = getForm();
                    //     var userData = window.btoa(JSON.stringify({name: formData.nome, phone: formData.telefone ,email: formData.email}));
                    //     if(userData)
                    //         return window.location.href = `https://quiz.${process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'dev.' : ''}cartaodetodos.com.br/meio-pagamento/?app_data=${userData}`;
                    // }
                }
            }
            else {
                var dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'event': 'erroPagamento',
                    'statusCode': '402',
                    'description': 'reCaptcha refused',
                });
            }


            alterarLoading(false);
        }
    };

    return {
        configuration,
        dto,
        getForm,
        handleSubmit,
        showPayment, alterarShowPayment,
        paymentAttempts, alterarPaymentAttempts,
        showError,
        alterarShowError,
        loading,
        recaptchaRef,
    };
}