import { ReactComponent as BgPage } from '../../assets/img/background.svg';
import { MobileDownloadsComponente } from "../../componentes/mobile_downlaods.componente";
import { HeaderComponente } from "../../componentes/header.component";
import { FooterComponente } from "../../componentes/footer.component";
import { Container, Row, Col } from "react-bootstrap"
import { Logic } from "./logic";
import "./style.scss";

import { ModalDiaNacionalSaudeComponente } from "../../componentes/modal_dia_nacional_saude.componente";
import { ModalOutubroRosaComponente } from "../../componentes/modal_outubro_rosa.componente";
import { ModalEsquentaBlackFridayComponent } from "../../componentes/modal_esquenta_black_friday";
import { ModalFiliacaoBlackFridayComponent } from "../../componentes/modal_filiacao_blackfriday";
import { ModalRefuturizaComponent } from '../../componentes/modal_refuturiza_componente';
import { ModalDiaDoConsumidorPosFiliacaoComponent } from '../../componentes/modal_dia_do_consumidor_pos_filiacao_component';
import ModalEvents from '../../componentes/modal_2024/modal_events';
import { ModalAvaliacao } from '../../componentes/modal_2024/modal-avaliacao';
import { ModalPosFiliacaoComponent } from '../../componentes/modal_pos_filiacao_component';
import { dateComparePeriod } from '../../utils/utils';


export default function Sucesso({ logic = Logic() }) {

  return (
    <>
      <main className="page-use-banner">
        <HeaderComponente configuration={logic.configuration} bgSolido={false} />
        <Container fluid="xxl" className="app-container">
          <Row className="vh-100 align-items-stretch">
            <Col md={12} xl={5} className="message-col page-success order-2 order-xl-1 align-self-center">

              <Row>
                <Col>
                  <img
                    src={logic.configuration.success?.image}
                    alt="Cartao"
                    className="img-card d-block d-xl-none"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3 mb-xl-4">
                  <h1 className="fw-normal text-center mb-0 d-none d-xl-block"><span className="fw-bold">Parabéns!</span> Agora você já pode usar <br /> o <span className="fw-bold">Verdinho mais amado do Brasil</span>!</h1>
                </Col>
                <Col sm={12}>
                  <h6 className="fw-normal text-center text-default mb-3 mb-xl-4">
                    Todos os benefícios já estão disponíveis&nbsp;
                    <br className="d-block d-xl-none" />
                    pra você!&nbsp;
                    <br className="d-none d-xl-block" />
                    Utilize sua matrícula para&nbsp;
                    <br className="d-block d-xl-none" />
                    aproveitar.
                  </h6>

                  <div className="card-payment-success bg-primary">
                    <h6 className="text-white">Seu número de matrícula</h6>
                    <h1 className="text-white">{logic.matricula}</h1>
                  </div>

                  <h5 className="fw-normal text-center mb-1 mb-xl-0 pt-2 pt-xl-0">Adicione até <span className="fw-bold">7 dependentes.</span><br />(Esposa, marido e filhos)</h5>

                  {/* <div className="text-center">
                <Button variant="primary" size="sm" type="button" onClick={() => logic.setModalShow(true)}>
                  Adicionar dependentes
                </Button>
              </div> */}

                  <MobileDownloadsComponente configuration={logic.configuration} />
                </Col>
                <Col className="px-0 px-lx-2">
                  <footer className="use-banner-footer">
                    <FooterComponente configuration={logic.configuration} divideTela={true} />
                  </footer>
                </Col>
              </Row>
            </Col>
            <Col md={12} xl={7} className="banner-col text-center d-flex flex-column flex-xl-row align-items-center order-1 order-xl-2">
              <BgPage className="bg-right-page fill-primary" />
              <h1 className="fw-normal text-center text-white d-block d-xl-none"><span className="fw-bold">Parabéns!</span> Agora você já pode<br /> usar o <span className="fw-bold">Verdinho mais amado<br /> do Brasil</span>!</h1>
              <img
                src={logic.configuration.success?.image}
                alt="Cartao"
                className="img-card d-none d-xl-block"
              />

            </Col>
          </Row>
        </Container>
      </main>

      {/* <ModalAdicionarDependentesComponente
          show={logic.modalShow}
          onHide={() => logic.setModalShow(false)}
          onSave={() => logic.handleSave()}
        /> */}

      <ModalDiaNacionalSaudeComponente
        show={logic.modalDiaNacionalSaudeShow}
        onHide={() => logic.setmodalDiaNacionalSaudeShow(false)}
      />

      <ModalOutubroRosaComponente
        show={logic.modalOutubroRosaShow}
        onHide={() => logic.setmodalOutubroRosaShow(false)}
      />

      <ModalEsquentaBlackFridayComponent
        dto={logic.dto}
        show={logic.modalEsquentaBlackFridayShow}
        onHide={() => logic.setmodalEsquentaBlackFridayShow(false)}
      />

      <ModalFiliacaoBlackFridayComponent
        show={logic.modalFiliacaoBlackFridayShow}
        onHide={() => logic.setmodalFiliacaoBlackFridayShow(false)}
      />

      <ModalRefuturizaComponent
        show={logic.modalRefuturiza}
        onHide={() => logic.setModalRefuturiza(false)}
      />

      <ModalDiaDoConsumidorPosFiliacaoComponent
        data={window.btoa(JSON.stringify({ cpf: logic.dto.cpf, email: logic.dto.email, matricula: logic.matricula }))}
        show={logic.modalPosFiliacao}
        onHide={() => logic.setModalPosFiliacao(false)}
      />

      {dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '07/26/2024' : '08/07/2024'}`, "08/11/2024").insidePeriod ? 

        <ModalPosFiliacaoComponent
          data={window.btoa(JSON.stringify({ cpf: logic.dto.cpf, email: logic.dto.email, matricula: logic.matricula }))}
          show={logic.modalEvents}
          onHide={() => logic.setModalEvents(false)}
        />
        :
        <ModalAvaliacao
          show={logic.modalEvents}
          onHide={() => logic.setModalEvents(false)}
        />
      }

      {/* Responsável por receber todos os eventos atuais de campanha */}
      {/* <ModalEvents
        data={window.btoa(JSON.stringify({ cpf: logic.dto.cpf, email: logic.dto.email, matricula: logic.matricula }))}
        show={logic.modalEvents}
        onHide={() => logic.setModalEvents(false)}
      /> */}

    </>
  )
}
