import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as IconFechar } from '../assets/icons/icon-close.svg';

export const ModalRefuturizaComponent = (props: any) => {

    return (
        <Modal
            {...props}
            className="modal-filiacao-black-friday"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Container className="p-0">
                    <Row className="close justify-content-end">
                        <Col className="d-flex align-items-center justify-content-end pointer" onClick={props.onHide}>
                            <strong className="text-white me-2">Fechar</strong>
                            <IconFechar stroke="white" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center ">
                        <Col className="col-auto">
                            <picture>
                                <source media="(max-width: 1199.98px)" srcSet="/todos/refuturiza-success-mobile.png" />
                                <source media="(min-width: 1200px)" srcSet="/todos/refuturiza-success-desktop.png" />
                                <img src="/todos/refuturiza-success-desktop.png" alt="Refuturiza sucesso" />
                            </picture>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
        </Modal>
    )
}
