import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PixService } from "../../servicos/pix.service";
import { Configuration } from "../../modelos";

export const Logic = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const processo = urlParams.get("processo");
    const recorrencia = urlParams.get("recorrencia") === 'true' ? true : false;

    const [configuration, alterarConfiguration] = useState(require("../inicio/configuration.json") as Configuration);
    const [pixName, setPixName] = useState<string>('');
    const [qrcode, setQrcode] = useState<string>('');
    const [codigoCopiaCola, setCodigoCopiaCola] = useState<string>('');
    const [copied, setCopied] = useState<boolean>(false);
    const [codeExpired, setCodeExpired] = useState<boolean>(false);
    
    useEffect(() => {
      if(processo !== null && processo !== ''){
        buscaQrCode(processo, recorrencia);
      }
      else
        window.location.replace("/" + window.location.search);
    }, [])
  
    const buscaQrCode = async (processo: string, recorrencia: boolean) => {
      try {
        var resp: any = await PixService().generateQrCode(processo, recorrencia);
        if (resp.success) {
          setPixName(resp.data.dadosPix.nome);
          setQrcode(resp.data.dadosPix.urlQRCodePix);
          setCodigoCopiaCola(resp.data.dadosPix.codigoCopiaEColaPIX);
        }
        else {
          setCodeExpired(true);
        }
      } catch (error) {
        console.error(error)
      }
    }

    const copyToClipboard = (e: any) => {
      if(codigoCopiaCola) {
        navigator.clipboard.writeText(codigoCopiaCola).then(() => {
            setCopied(true);
        }, () => {
            setCopied(false);
        });
      }
    };
    
    
    return {
      configuration,
      pixName,
      qrcode,
      codigoCopiaCola,
      copied,
      codeExpired,
      copyToClipboard
    };
}