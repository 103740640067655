import { Cpf } from "../modelos";
import axiosInstance from "../utils/axiosInstance";

export const CpfService = () => {
    const validarCpf = async (cpf: string) => {
        try {
            var response = await axiosInstance.get<Cpf>(`/validate/cpf/${cpf}`);
            return response.data;
        } catch (error) {
            return undefined;
        }
    };

    const getFiliado = async (matricula: string) => {
        try {
            var response = await axiosInstance.get<Cpf>(`/filiado/matricula/${matricula}`);
            return response.data;
        } catch (error) {
            return undefined;
        }
    }

    return { validarCpf, getFiliado };
}