import { ActionService } from "../../servicos/action.service";
import { acceptIDPVs3DS, getCardType, removeMask, validarCPF, getIdpv, uuid, redirectIDPVsNew3DSPiloto, redirectIDPV, redirectPageIDPVsNew3DS } from "../../utils/utils";
import { FiliacaoService, LeadService, ThreeDsService } from "../../servicos";
import { CheckoutDTO } from "../../dto";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { ThreeDSModel } from "../../dto/threeDS.dto";
import { CheckoutContext } from "../../context/checkout.provider";
import { DatalayerAddPaymentInfo, DataLayerAdicionais } from "../../utils/datalayer";

export const Logic = (
    props: {
        alterarShowError: any,
        configuration: any,
        handleSubmit: any,
        form: any,
        formularioPagamento: any,
        desabilitarEndereco: any,
        valorRefuturiza: any,
        alterarValorRefuturiza: any,
        alterarLoading: any,
        recaptchaRef: any
    }) => {
    const {
        nome: nomeUsuario,
        email,
        cpf: cpfUsuario,
        telefone,
        termos,
        maior_dezoito,
        cep,
        rua,
        cidade,
        estado,
        voucher,
        adesao,
        mensalidade,
        adesaoVoucher,
        mensalidadeVoucher,
        methodSelected,
        setMethodSelected,
        franquiaId,
        total
    } = useContext(CheckoutContext);

    const [sendedLead, alterarSendedLead] = useState(false);

    const [mostrarCredito, alterarMostrarCredito] = useState(false);
    const [mostrarDebito, alterarMostrarDebito] = useState(false);
    const [mostrarPix, alterarMostrarPix] = useState(false);

    const [bankName, setBankName] = useState('');
    const [method, setMethod] = useState('');

    const [numeroCartao, alterarNumeroCartao] = useState("");
    const [numeroCartaoValido, alterarNumeroCartaoValido] = useState(false);
    const [numeroCartaoInvalido, alterarNumeroCartaoInvalido] = useState(false);
    const [numeroCartaoMensagemErro, alterarNumeroCartaoMensagemErro] = useState("Campo obrigatório");

    const [valorRefuturiza, alterarValorRefuturiza] = useState(false);
    const [valorCashbackEDT, alterarValorCashbackEDT] = useState(false);

    const [valorMensalidade, setValorMensalidade] = useState(0);
    const [pagamentoInicial, setPagamentoInicial] = useState(0);
    const [valorAdesao, setValorAdesao] = useState(0);

    const [loading, alterarLoading] = useState(false);
    const [eventSubmit, setEventSubmit] = useState<any>("");

    const [threeDSCardId, setThreeDSCardId] = useState("");
    const [threeDSCardId2, setThreeDSCardId2] = useState("");
    const [threeDSTiD1, setThreeDSTiD1] = useState("");
    const [threeDSTiD2, setThreeDSTiD2] = useState("");
    const [threeDSNeedFingerPrint1, setThreeDSNeedFingerPrint1] = useState(true);
    const [threeDSNeedFingerPrint2, setThreeDSNeedFingerPrint2] = useState(true);
    const [threeDSFingerPrint, setThreeDSFingerPrint] = useState<any>({});
    const [threeDSFingerPrint2, setThreeDSFingerPrint2] = useState<any>({});
    const [threeDSErrorPayment, setThreeDSErrorPayment] = useState<boolean>(false);
    const [threeDSGuid, setThreeDSGuid] = useState<string>('');


    const [idpvD3S, setIdpv3DS] = useState(false);

    const [modalLoading3DS, setModalLoading3DS] = useState(false);
    const [percentLoading, setPercentLoading] = useState(0);

    const [modalAvisoPixShow, setModalAvisoPixShow] = useState(false);
    const [numeroWhatsApp, setNumeroWhatsApp] = useState('');

    const [recaptchaToken, setRecaptchaToken] = useState<any>();

    useEffect(() => {
         if(redirectIDPVsNew3DSPiloto(getIdpv().value)) {
            // https://idpv.stage.cartaodetodos.com.br
            redirectPageIDPVsNew3DS();
        }
        // if(acceptIDPVs3DS(getIdpv().value)) {
        //     setBankName('outrosbancos');
        //     setIdpv3DS(acceptIDPVs3DS(getIdpv().value));
        // }
        setBankName('outrosbancos');
        setIdpv3DS(true);
        window.sessionStorage.removeItem('_modalConfirmWhatsapp');
        setThreeDSGuid(uuid());

    }, []);


    useEffect(() => {
        setValorMensalidade(props.configuration.prices!.mensalidade!);
        setPagamentoInicial(props.configuration.prices!.mensalidade! + props.configuration.prices!.adesao!);
        setValorAdesao(props.configuration.prices!.adesao!);
    });

    // useEffect(() => {
    //     console.log('adesão', adesaoVoucher);
    //     console.log('mensalidade', mensalidadeVoucher);
    // }, [adesaoVoucher, mensalidadeVoucher])

    useEffect(() => {
        setMethodSelected(method);
        if (method !== 'credito') {
            alterarValorRefuturiza(false);
        }
    }, [method])

    useEffect(() => {
        localStorage.setItem("refuturiza", JSON.stringify(valorRefuturiza));       

        if (email && telefone && total) {
            DataLayerAdicionais(
                {
                    optInSms: termos,
                    formPhone: telefone,
                    formEmail: email,
                    price: total,
                    voucher,
                    isRefuturiza: valorRefuturiza,
                    isEnergiaTodos: valorCashbackEDT
                }
            );
        }
    }, [valorRefuturiza]);

    useEffect(() => {
        localStorage.setItem("cashbackedt", JSON.stringify(valorCashbackEDT));

        if (email && telefone && total) {
            DataLayerAdicionais(
                {
                    optInSms: termos,
                    formPhone: telefone,
                    formEmail: email,
                    price: total,
                    voucher,
                    isRefuturiza: valorRefuturiza,
                    isEnergiaTodos: valorCashbackEDT
                }
            );
        }
        
    }, [valorCashbackEDT]);

    function verificarNumeroCartao() {
        if (props.alterarShowError)
            props.alterarShowError(false);

        var numeroCartaoTratado = removeMask(numeroCartao);
        var mesage = numeroCartaoMensagemErro;

        alterarNumeroCartao(numeroCartaoTratado);
        alterarNumeroCartaoInvalido(numeroCartaoTratado.length < 16);
        alterarNumeroCartaoValido(numeroCartaoTratado.length >= 16);

        var bandeira = getCardType(numeroCartao)
        var bandeirasBradesco = /visa|elo/;

        if (numeroCartaoTratado.length <= 0)
            mesage = "Campo obrigatório";
        else
            mesage = "Número do cartão inválido";

        if (bankName === 'bradesco') {
            if (!bandeirasBradesco.test(bandeira) && numeroCartaoTratado.length >= 16) {
                mesage = `A bandeira ${bandeira} não é aceita para este banco.`;
                alterarNumeroCartaoInvalido(true);
                alterarNumeroCartaoValido(false);
            }
        }

        alterarNumeroCartaoMensagemErro(mesage);

        // ActionService().sendInvalidField(numeroCartaoTratado.length < 16, mesage, numeroCartao, "numeroCartao", props.configuration.ga_codigo!);
    }

    const [nome, alterarNome] = useState("");
    const [nomeValido, alterarNomeValido] = useState(false);
    const [nomeInvalido, alterarNomeInvalido] = useState(false);
    const [nomeMensagemErro, alterarNomeMensagemErro] = useState("Campo obrigatório");
    function verificarNome() {
        if (props.alterarShowError)
            props.alterarShowError(false);

        var hasNumber = /\d/;
        var message = nomeMensagemErro;
        var invalid = nome.length < 3 || hasNumber.test(nome);

        alterarNomeInvalido(invalid);
        alterarNomeValido(nome.length >= 3 && !hasNumber.test(nome));

        if (nome.length <= 0)
            message = "Campo obrigatório";
        else if (hasNumber.test(nome)) {
            message = "Nome inválido";
            alterarNomeInvalido(true);
            alterarNomeValido(false);
        }
        else
            message = "Digite seu nome completo";


        alterarNomeMensagemErro(message);

        // ActionService().sendInvalidField(invalid, message, nome, "nomeCartao", props.configuration.ga_codigo!);
    }

    const [cpf, alterarCpf] = useState("");
    const [cpfValido, alterarCpfValido] = useState(false);
    const [cpfInvalido, alterarCpfInvalido] = useState(false);
    const [cpfMensagemErro, alterarCpfMensagemErro] = useState("Campo obrigatório");
    function verificarCpf() {
        if (props.alterarShowError)
            props.alterarShowError(false);

        var cpfTratado = removeMask(cpf);
        var eValido: boolean = validarCPF(cpfTratado);
        var message = cpfMensagemErro;

        alterarCpf(cpfTratado);
        alterarCpfInvalido(!eValido);
        alterarCpfValido(eValido);

        if (cpfTratado.length <= 0)
            alterarCpfMensagemErro("Campo obrigatório");
        else {
            message = 'CPF inválido';
            alterarCpfMensagemErro(message);
        }

        // ActionService().sendInvalidField(!eValido, message, cpfTratado, "cpfCartao", props.configuration.ga_codigo!);
    }

    const [validade, alterarValidade] = useState("");
    const [validadeValido, alterarValidadeValido] = useState(false);
    const [validadeInvalido, alterarValidadeInvalido] = useState(false);
    const [validadeMensagemErro, alterarValidadeMensagemErro] = useState("Campo obrigatório");
    function verificarValidade() {
        if (props.alterarShowError)
            props.alterarShowError(false);
        var validadeTratado = removeMask(validade);


        var mesAtual = new Date().getMonth() + 1;
        var anoAtual = Number.parseInt(new Date().getFullYear().toString().substring(2, 4));
        var anoInputed = Number.parseInt(validadeTratado.substring(2, 4));
        var mesInputed = Number.parseInt(validadeTratado.substring(0, 2));
        var invalid = false;
        var message = validadeMensagemErro;

        if ((anoInputed < anoAtual || mesInputed > 12) && validadeTratado.length === 4) {
            invalid = true;
            message = anoInputed < anoAtual ? "Ano inválido" : "Mês inválido";

            alterarValidadeInvalido(invalid);
            alterarValidadeMensagemErro(message);
        } else {
            alterarValidadeInvalido(false);

            if (anoInputed === anoAtual && mesInputed < mesAtual) {
                invalid = true;
                message = "Mês inválido";

                alterarValidadeInvalido(invalid);
                alterarValidadeMensagemErro(message);
            } else {
                invalid = validadeTratado.length < 3;

                alterarValidade(validadeTratado);
                alterarValidadeInvalido(invalid);
                alterarValidadeValido(validadeTratado.length >= 3);

                if (validadeTratado.length <= 0) {
                    message = "Campo obrigatório";
                }
                else {
                    message = "Cartão fora da Validade";
                }

                alterarValidadeMensagemErro(message);
            }
        }

        // ActionService().sendInvalidField(invalid, message, validade, "validadeCartao", props.configuration.ga_codigo!);
    }

    const [cvv, alterarCvv] = useState("");
    const [cvvValido, alterarCvvValido] = useState(false);
    const [cvvInvalido, alterarCvvInvalido] = useState(false);
    const [cvvMensagemErro, alterarCvvMensagemErro] = useState("Campo obrigatório");
    function verificarCvv() {
        if (props.alterarShowError)
            props.alterarShowError(false);

        var cvvTratado = removeMask(cvv);

        var invalid = cvvTratado.length < 3;
        var message = cvvMensagemErro;

        alterarCvv(cvvTratado);
        alterarCvvInvalido(invalid);
        alterarCvvValido(cvvTratado.length >= 3);

        if (cvvTratado.length <= 0)
            message = "Campo obrigatório";
        else
            message = "CVV inválido";

        if (bankName === 'itau') {
            alterarCvv('');
            alterarCvvInvalido(false);
            alterarCvvValido(true);
        }

        alterarCvvMensagemErro(message);

        // ActionService().sendInvalidField(invalid, message, cvv, "cvv", props.configuration.ga_codigo!);

    }

    async function handleSubmit(event: any) {
        // console.log("chamou handleSubmit");

        if (props.alterarShowError)
            props.alterarShowError(false);
        event?.preventDefault();

        verificarNumeroCartao();
        verificarNome();
        verificarValidade();
        verificarCvv();
        verificarCpf();

        if (numeroCartaoValido && nomeValido && validadeValido && cvvValido && cpfValido) {


            var generationtime = new Date().toISOString();

            const options = {
                cardTypeElement: 0,
                onsubmit: function (e: any) {
                    e.preventDefault();
                }
            };

            const encryptedForm = window.adyen.encrypt.createEncryptedForm(props.form.current, process.env.REACT_APP_API_KEY_ADYEN, options);
            const cardData = {
                number: numeroCartao,
                cvc: cvv,
                holderName: nome,
                expiryMonth: validade.substring(0, 2),
                expiryYear: validade.substring(2, 4),
                generationtime
            };
            const cryptData = encryptedForm.encrypt(cardData);

            var formCartao: any = cardData;
            formCartao.bankName = bankName;
            formCartao.method = method;
            formCartao.cryptData = cryptData;

            // if(bankName === 'outrosbancos') {
            formCartao.threeDSPayment = true;
            formCartao.referencia = threeDSGuid;
            formCartao.threeDSCardId = threeDSCardId;
            formCartao.threeDSCardId2 = threeDSCardId2;
            formCartao.threeDSTiD1 = threeDSTiD1;
            formCartao.threeDSTiD2 = threeDSTiD2;
            formCartao.threeDSNeedFingerPrint1 = threeDSNeedFingerPrint1;
            formCartao.threeDSNeedFingerPrint2 = threeDSNeedFingerPrint2;
            formCartao.threeDSFingerPrint = [threeDSNeedFingerPrint1 ? JSON.stringify(threeDSFingerPrint) : "", threeDSNeedFingerPrint2 ? JSON.stringify(threeDSFingerPrint2) : ""];
            // console.log('formCartaoSubmit', formCartao);
            // }

            setTimeout(() => {
                setModalLoading3DS(false);
            }, 2000);
            props.handleSubmit(event, formCartao, recaptchaToken);
        }
        else {
            props.handleSubmit(event);
        }
        setEventSubmit('');
    }

    const callRecaptcha = async () => {
        // window.grecaptcha.enterprise.ready(async () => {});
        var captchaToken = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'checkout' });
        return captchaToken;
    }

    async function handle3DSSubmit(event: any) {
        // console.log("chamou handle3DSSubmit");

        props.alterarLoading(true);
        if (props.alterarShowError)
            props.alterarShowError(false);

        event?.preventDefault();
        event?.stopPropagation();

        verificarNumeroCartao();
        verificarNome();
        verificarValidade();
        verificarCvv();
        verificarCpf();

        if (numeroCartaoValido && nomeValido && validadeValido && cvvValido && cpfValido) {
            setEventSubmit(event);
            setModalLoading3DS(true);

            var userData: ThreeDSModel =
            {
                idServico: getIdpv().has ? 4 : 19,
                referencia: threeDSGuid,
                idFranquia: franquiaId,
                cpf: cpfUsuario,
                exp_date: `${validade.substring(0, 2)}/20${validade.substring(2, 4)}`,
                holder: nome,
                number: numeroCartao,
                cvv: cvv,
                brand: getCardType(numeroCartao),
                installments: 1,
                value: process.env.REACT_APP_ENVIRONMENT === "stage" ? 2 : (voucher !== '' ? adesaoVoucher : adesao),
                meta: {
                    id: "checkout_cdt_3ds2"
                },
                three_ds2_data: {
                    browserInfo: {
                        acceptHeader: "*/*",
                        colorDepth: window.screen.colorDepth,
                        language: navigator.language,
                        javaEnabled: false,
                        screenHeight: window.innerHeight,
                        screenWidth: window.innerWidth,
                        userAgent: navigator.userAgent,
                        timeZoneOffset: new Date().getTimezoneOffset()
                    }
                }
            };

            var captchaToken = await callRecaptcha();
            if (captchaToken) {
                setRecaptchaToken(captchaToken);

                // console.log('userData to generate 3DS2 data', userData);
                getFingerPrint(userData, '#threeDSFingerPrint_result');

                userData.idServico = getIdpv().has ? 1 : 18;
                userData.value = process.env.REACT_APP_ENVIRONMENT === "stage" ? 2 : (voucher !== '' ? mensalidadeVoucher : mensalidade);
                // console.log('userData to generate 3DS2 data', userData);
                getFingerPrint(userData, '#threeDSFingerPrint_result2');
            }
        }
    }

    async function handleSubmitModalPix(event: any) {
        event?.preventDefault();
        const data = new FormData(event.target);
        const numeroWhatsapp: any = data.get('numeroWhatsapp');
        setNumeroWhatsApp(removeMask(numeroWhatsapp));
        setModalAvisoPixShow(false);
    }

    async function handlePixSubmit(event: any) {
        event?.preventDefault();
        event?.stopPropagation();

        var formCartao: any = {
            method,
            numeroWhatsApp
        };

        if (nomeUsuario && email && cpfUsuario && telefone && termos && maior_dezoito && cep && rua && cidade && estado) {
            let modalConfirmWhatsapp = window.sessionStorage.getItem('_modalConfirmWhatsapp');

            if (modalConfirmWhatsapp !== null && modalConfirmWhatsapp === 'true') {
                props.handleSubmit(event, formCartao);
            }
            else {
                setModalAvisoPixShow(true);
                window.sessionStorage.setItem('_modalConfirmWhatsapp', 'true');
                setEventSubmit(event);
            }
        }
    }

    useEffect(() => {
        if (modalAvisoPixShow === false) {

            let modalConfirmWhatsapp = window.sessionStorage.getItem('_modalConfirmWhatsapp');
            if (modalConfirmWhatsapp !== null && modalConfirmWhatsapp === 'true') {
                handlePixSubmit(eventSubmit);
            }
        }

    }, [modalAvisoPixShow])


    async function getFingerPrint(cardData: ThreeDSModel, elementId: string) {
        const paymentMethods = {
            paymentMethods: [
                {
                    brands: [
                        "maestro",
                        "visa",
                    ],
                    details: [
                        {
                            key: "number",
                            type: "text",
                        },
                        {
                            key: "expiryMonth",
                            type: "text",
                        },
                        {
                            key: "expiryYear",
                            type: "text",
                        },
                        {
                            key: "cvc",
                            type: "text",
                        },
                        {
                            key: "holderName",
                            optional: true,
                            type: "text",
                        },
                    ],
                    name: "Credit Card",
                    type: "scheme",
                },
                {
                    name: "Boleto Bancario",
                    type: "boletobancario_santander",
                },
            ],
        };

        // console.log('Gerando 3DS2 data...');

        const respGet3DS2 = await ThreeDsService().get3DS2(cardData);
        setPercentLoading(percentLoading + 20);

        const configuration_to_fingerprint: any = {
            locale: "pt_BR",
            environment: process.env.REACT_APP_ENVIRONMENT === "dev" ? "test" : "live",
            clientKey: process.env.REACT_APP_ENVIRONMENT === "dev" ? "test_HYFSWUFA7VCK7F3JKNOO7CHRZEF4OKUK" : "live_BEWAVTCQ2FHW5JGLRGUW23WNP47ROEJM", // Your client key. To find out how to generate one, see https://docs.ad
            paymentMethodsResponse: paymentMethods, // The payment methods response returned in step 1.
        };

        // 05/01/24
        // if(typeof(respGet3DS2) === "object" && respGet3DS2.success) {
        if (typeof (respGet3DS2) === "object" && respGet3DS2.success && respGet3DS2.data.response.hasOwnProperty('card_id')) {

            if (elementId === '#threeDSFingerPrint_result') {

                setThreeDSCardId(respGet3DS2.data.response.card_id);
                setThreeDSTiD1(respGet3DS2.data.response.tid);

                if (!respGet3DS2.data.response.perform_3ds2_flow && respGet3DS2.data.response.authorized) {
                    setThreeDSNeedFingerPrint1(false);
                    setThreeDSFingerPrint("");
                }
                else if (!respGet3DS2.data.response.perform_3ds2_flow && !respGet3DS2.data.response.authorized) {
                    setThreeDSNeedFingerPrint1(false);
                    setThreeDSFingerPrint("");
                    setThreeDSErrorPayment(true);
                }
                else {
                    setThreeDSNeedFingerPrint1(true);
                    console.log('respGet3DS2_1', respGet3DS2);
                    console.log('Gerando threeDSFingerPrint data...');

                    configuration_to_fingerprint.onAdditionalDetails = handleOnAdditionalToFingerPrintDetails
                }
            }
            else {
                // respGet3DS2.data.response.perform_3ds2_flow = true;
                // respGet3DS2.data.response.authorized = true;

                setThreeDSCardId2(respGet3DS2.data.response.card_id);
                setThreeDSTiD2(respGet3DS2.data.response.tid);

                if (!respGet3DS2.data.response.perform_3ds2_flow && respGet3DS2.data.response.authorized) {
                    setThreeDSNeedFingerPrint2(false);
                    setThreeDSFingerPrint2("");
                }
                else if (!respGet3DS2.data.response.perform_3ds2_flow && !respGet3DS2.data.response.authorized) {
                    setThreeDSNeedFingerPrint1(false);
                    setThreeDSFingerPrint("");
                    setThreeDSErrorPayment(true);
                }
                else {
                    setThreeDSNeedFingerPrint2(true);

                    console.log('respGet3DS2_2', respGet3DS2);
                    console.log('Gerando threeDSFingerPrint 2 data...');
                    configuration_to_fingerprint.onAdditionalDetails = handleOnAdditionalToFingerPrintDetails2
                }
            }
            const checkoutAdyen = new window.AdyenCheckout(configuration_to_fingerprint);
            const threeDSConfiguration = {
                challengeWindowSize: '05'
            };
            var action = respGet3DS2.data.response.gateway_response.action;
            checkoutAdyen.createFromAction(action, threeDSConfiguration).mount(elementId);
        }
        // 05/01/24
        else {
            setThreeDSCardId("");
            setThreeDSTiD1("");
            setThreeDSNeedFingerPrint1(false);
            setThreeDSFingerPrint("");
            setThreeDSErrorPayment(true);
            setThreeDSCardId2("");
            setThreeDSTiD2("");
            setThreeDSNeedFingerPrint2(false);
            setThreeDSFingerPrint2("");
        }

        // if (elementId === '#threeDSFingerPrint_result') {
        //     setThreeDSCardId(respGet3DS2.data.response.card_id);
        //     setPercentLoading(percentLoading + 20); 


        //     // console.log('respGet3DS2_1', respGet3DS2);
        //     // console.log('Gerando threeDSFingerPrint data...');

        //     configuration_to_fingerprint.onAdditionalDetails = handleOnAdditionalToFingerPrintDetails
        // }
        // else {
        //     setThreeDSCardId2(respGet3DS2.data.response.card_id);
        //     setPercentLoading(percentLoading + 20);


        //     // console.log('respGet3DS2_2', respGet3DS2);
        //     // console.log('Gerando threeDSFingerPrint 2 data...');      
        //     configuration_to_fingerprint.onAdditionalDetails = handleOnAdditionalToFingerPrintDetails2
        // }

        // const checkoutAdyen = new window.AdyenCheckout(configuration_to_fingerprint);
        // const threeDSConfiguration = {
        //     challengeWindowSize: '05'
        // };
        // var action = respGet3DS2.data.response.gateway_response.action;
        // checkoutAdyen.createFromAction(action, threeDSConfiguration).mount(elementId);

    }

    function handleOnAdditionalToFingerPrintDetails(state, component) {
        // console.log('threeDSFingerPrint', state.data);
        return setThreeDSFingerPrint(state.data);
    };

    function handleOnAdditionalToFingerPrintDetails2(state, component) {
        // console.log('threeDSFingerPrint2', state.data);
        return setThreeDSFingerPrint2(state.data);
    };

    useEffect(() => {
        setPercentLoading(percentLoading + 20);

        var ds1 = false;
        var ds2 = false;
        if (threeDSNeedFingerPrint1) {
            if (threeDSFingerPrint.details) {
                ds1 = true
            }
        }
        else ds1 = true;
        if (threeDSNeedFingerPrint2) {
            if (threeDSFingerPrint2.details) {
                ds2 = true
            }
        }
        else ds2 = true;
        if (ds1 && ds2)
            handleSubmit(eventSubmit);

        // if(threeDSFingerPrint.details && threeDSFingerPrint2.details)
        //     handleSubmit(eventSubmit);

    }, [threeDSFingerPrint, threeDSFingerPrint2]);

    useEffect(() => {
        if (threeDSErrorPayment) {
            handleSubmit(eventSubmit);
        }
    }, [threeDSErrorPayment])


    const openCredit = () => {
        alterarMostrarCredito(!mostrarCredito);
        alterarMostrarDebito(false);

        // if (!sendedLead && props.formularioPagamento !== undefined) {
        //     alterarSendedLead(true);
        //     var form: CheckoutDTO = props.formularioPagamento();
        //     form.etapalead = "Pagamento";
        //     LeadService().sendLead(form);
        //     props.desabilitarEndereco();
        // }
    }

    function enviarEtapaLead() {
        if (!sendedLead && props.formularioPagamento !== undefined) {
            alterarSendedLead(true);
            var form: CheckoutDTO = props.formularioPagamento();
            form.etapalead = "Pagamento";
            // if ( window.location.host.search('solicite.') < 0 ) //TODO: Regra checkout multi url
            LeadService().sendLead(form);
            if (props.desabilitarEndereco)
                props.desabilitarEndereco();
        }
    }

    useEffect(() => {
        if (numeroCartaoValido === true) {
            DatalayerAddPaymentInfo({
                price: props.configuration.prices.mensalidade + props.configuration.prices.adesao,
                idpv: idpv,
                isRefuturiza: valorRefuturiza,
                isEnergiaTodos: valorCashbackEDT
            })
        }
    }, [numeroCartaoValido])

    return {
        alterarMostrarCredito, mostrarCredito, mostrarDebito, mostrarPix,
        bankName, setBankName,
        method, setMethod,
        numeroCartao, alterarNumeroCartao, numeroCartaoValido, numeroCartaoInvalido,
        numeroCartaoMensagemErro, nome, alterarNome, nomeValido, nomeInvalido, nomeMensagemErro, validade, alterarValidade, validadeValido,
        validadeInvalido, validadeMensagemErro, cvv, alterarCvv, cvvValido, cvvInvalido, cvvMensagemErro, cpf, alterarCpf, cpfValido, cpfInvalido,
        cpfMensagemErro, verificarNumeroCartao, verificarNome, verificarCpf, verificarValidade, verificarCvv, handleSubmit, handle3DSSubmit, handlePixSubmit, getFingerPrint, openCredit, enviarEtapaLead,
        valorRefuturiza, alterarValorRefuturiza,
        valorCashbackEDT, alterarValorCashbackEDT,
        valorMensalidade, pagamentoInicial, valorAdesao, loading, alterarLoading, idpvD3S,
        modalLoading3DS, setModalLoading3DS, modalAvisoPixShow, setModalAvisoPixShow,
        handleSubmitModalPix,
        percentLoading
    };
}