import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const Logic = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    return {
        
    };
}