import axios from "axios";

export interface DataProps {
    amount: any,
    eventID: string,
    payname: string,
    currency: string,
    voucherCode: string
}

export const KwankoService = () => {
    const KwankoTracking = async (data: DataProps) => {
        try {
            var response = await axios.get(`https://action.metaffiliation.com/trk.php?%20mclic=G512EA11013&argmon=${data.amount}&argann=${data.eventID}&argmodp=${data.payname}&nacur=${data.currency}&argbr=${data.voucherCode}`);
            return response.data;
        } catch (error) {
            return undefined;
        }
    };
    return { KwankoTracking };
}