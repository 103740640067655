import { useState } from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import { ReactComponent as IconFechar } from '../assets/icons/icon-close.svg';
import InputMask from "react-input-mask";
import { removeMask } from "../utils/utils";

export const ModalAvisoPixComponent = (props: any) => {
  const [telefone, alterarTelefone] = useState("");
  const [telefoneValido, alterarTelefoneValido] = useState(false);
  const [telefoneInvalido, alterarTelefoneInvalido] = useState(false);
  const [telefoneMensagemErro, alterarTelefoneMensagemErro] = useState("Campo obrigatório");
  function verificarTelefone() {
      var telefoneTratado = removeMask(telefone);
      var message = telefoneMensagemErro;

      alterarTelefone(telefoneTratado);
      alterarTelefoneInvalido(telefoneTratado.length < 11);
      alterarTelefoneValido(telefoneTratado.length >= 11);

      if (telefoneTratado.length <= 0) {
          message = "Campo obrigatório";
          alterarTelefoneMensagemErro(message);
      }
      else {
          message = "Telefone inválido";
          alterarTelefoneMensagemErro(message);
      }

  }


  return (
    <Modal
      {...props}
      size="xl"
      className="modal-pix-confirm-whats"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header>
 
      <Modal.Body className="default">
        <Row className="align-items-center flex-column flex-lg-row">
          <Col className="order-2 order-lg-1">
            <h1 className="text-text-link">
                Para seguir precisamos da{' '}
                <br className="d-none d-xl-block" />
                confirmação via Whatsapp
            </h1>
            
            <Row>
              <div className="col col-lg-6">
              <Form onSubmit={props.handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Número whatsapp</Form.Label>
                  <Form.Control
                    id="numeroWhatsapp"
                    name="numeroWhatsapp"
                    type="text"
                    as={InputMask}
                    maskChar=''
                    mask="(99) 99999-9999"
                    value={telefone}
                    placeholder="(11) 99999-9999"
                    onChange={(event) => alterarTelefone(event.target.value)}
                    onBlur={verificarTelefone}
                    isValid={telefoneValido}
                    isInvalid={telefoneInvalido}
                    enterKeyHint="next"
                    required
                  />
                  <Form.Control.Feedback type="invalid">{telefoneMensagemErro}</Form.Control.Feedback>
                </Form.Group>
                <Button variant="secondary" type="submit">Confirmar número</Button>
              </Form>
              </div>
            </Row>
          </Col>
          <Col className="col-auto pt-4 pt-lg-0 order-1 order-lg-2">
            <img src="/todos/ilustracao-pix-whatsapp.svg" alt="Confirmação do número do Whatsapp" />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
