import { CheckoutDTO } from "../dto";
import axiosInstance from "../utils/axiosInstance";

export const LeadService = () => {
    const sendLead = async (data: CheckoutDTO) => {
        data.hubspot_context = {
            "hutk": `${getCookie("hubspotutk")}`,
            "pageUri": window.location.href,
            "pageName": document.title,
        }
        if(getParam("utm_source=").value?.toLocaleLowerCase() === "bet7k") {
            data.origem_do_lead = "Bet7k";
        }
        try {
            await axiosInstance.post(`/lead`, JSON.stringify(data))
        } catch (error) {
            return undefined;
        }
    };

    return { sendLead };
}