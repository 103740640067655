import { useState } from "react";
import { Modal, Container, Row, Col, Button, Carousel } from "react-bootstrap";
import { ReactComponent as IconFechar } from '../../assets/icons/icon-close.svg';

import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as StarYellowIcon } from '../../assets/icons/star-yellow.svg';
import { AvaliacaoService } from "../../servicos/avaliacao.service";


export const ModalAvaliacao = (props: any) => {

  const [rating, setRating] = useState(0);
  const [sendSuccess, setSendSuccess] = useState(false);

  const handleClick = (value) => {
    setRating(value);
  };

  async function handleSendRating() {
    setSendSuccess(true);    

    const data = {
      IdPromotorVendas: getCookie('_idpv') ? getCookie('_idpv') : null, 
      notaNpsVendedor: rating,
      observacao: null,
      canalVendas: "Checkout"
    }

    AvaliacaoService().sendNPS(data);
  }


  return (
    <Modal
      {...props}
      size="xl"
      className="modal-avaliacao"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header>

      <Modal.Body>
        <Row className="align-items-center">

          {!sendSuccess ? (
            <Col className="btn-contentorder-1 order-xl-0">

              <h1 className="text-center text-">
                Sua opinião é importante para nós!
              </h1>

              <p className="text-center">
                Avalie sua última experiência com nossos atendentes e nos <br /> ajude a melhorar ainda mais.
              </p>

              <div className="star-content">

                <div className="star-rating">
                  {[...Array(5)].map((_, item) => (
                    <button
                      key={item}
                      className="star"
                      onClick={() => handleClick(item + 1)}
                    >
                      {item < rating ? <StarIcon /> : <StarYellowIcon />}
                    </button>
                  ))}
                </div>

              </div>

              <div className="btn-content">
                <button onClick={() => handleSendRating()} className='btn btn-event'>Enviar</button>
              </div>
            </Col>
          ) : (
            <Col className="btn-contentorder-1 order-xl-0">

              <h1 className="text-center text-">
                Avaliação enviada com sucesso!
              </h1>

              <p className="text-center">
                Obrigado por compartilhar sua opinião conosco! Sua avaliação <br /> nos inspira a continuar evoluindo.
              </p>

              <div className="btn-content">
                <button onClick={props.onHide} className="btn-link">Fechar e seguir</button>
              </div>
            </Col>
          )}

        </Row>
      </Modal.Body>
    </Modal>
  );
};
