import { BrowserRouter, Route, Routes as DomRoutes } from 'react-router-dom';
import Pagamento from './paginas/pagamento';
import Analise from './paginas/analise';
import Sucesso from './paginas/sucesso';
import Inicio from './paginas/inicio';
import Pix from './paginas/pix';
import NotFound from './paginas/404';
import SucessoPix from './paginas/sucesso-pix';

// FLUXO => Início - Pagamento - (Análise) Cartão de crédito análise - Sucesso - Dependentes Sucesso

function Routes() {
    return (
        <BrowserRouter>
            <DomRoutes>
                <Route element={<Inicio />} path="/" />
                <Route element={<Inicio />} path="/solicite" />
                <Route element={<Pagamento />} path="/pagamento" />
                <Route element={<Analise />} path="/analise" />
                <Route element={<Sucesso />} path="/sucesso" />
                <Route element={<SucessoPix />} path="/sucesso-pix" />
                <Route element={<Pix />} path="/pix" />
                <Route element={<NotFound />} path="*" />
                {/* <Route element={<DependentesSucesso />} path="/dependentes-sucesso" /> */}
            </DomRoutes>
        </BrowserRouter>
    )
}

export default Routes