import { ReactComponent as IconPhone } from '../../assets/icons/icon-phone.svg';
import { ReactComponent as IconCheckAll } from '../../assets/icons/icon-check-all.svg';
import { ReactComponent as IconReload } from '../../assets/icons/icon-reload.svg';
import { MetodoPagamentoComponente } from '../../componentes/metodo_pagamento';
import { FooterComponente } from "../../componentes/footer.component";
import { HeaderComponente } from "../../componentes/header.component";
import { Col, Container, Row } from "react-bootstrap"
import React, { useEffect } from "react";
import { Logic } from "./logic";
import "./style.scss";
import ReCAPTCHA from "react-google-recaptcha";

export default function SucessoPix({ logic = Logic() }) {

  return (
    <>
      {logic.showPayment ?
        <ReCAPTCHA
          ref={logic.recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        />
        :
        <></>
      }
      <main>
        <HeaderComponente configuration={logic.configuration} bgSolido={true} />
        <Container className="px-5">
          <Row className="h-responsive align-items-start align-items-lg-center analise-page">
            <div className='col'>
              <Row>
                <Col sm={12}>
                    <h2 className='text-success'>
                      Vamos enviar o link com o código PIX para o número do Whatsapp informado.
                    </h2>
                </Col>
              </Row>
            </div>
            <div className="col-auto d-flex justify-content-center align-items-center p-0 mt-3 mt-lg-0"
            >
              <img src="/todos/ilustracao-pix-whatsapp.svg" alt="Pix Whatsapp" />
            </div>
          </Row>
        </Container>
      </main>

      <footer>
        <FooterComponente
          configuration={logic.configuration}
          divideTela={false}
        />
      </footer>
    </>
  );
}
