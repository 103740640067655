import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': `${process.env.REACT_APP_API_SECRET}`,
  },
});

export default axiosInstance;