import axiosInstance from "../utils/axiosInstance";
import { ServiceValuesModel } from "../modelos";

export const ServicoService = () => {
    const buscarValorServico = async (idServico: number) => {

        try {
            var response = await axiosInstance.get<ServiceValuesModel>(`/servico/${idServico}`);
            if(response.data.valor)
                response.data.valor = Number(response.data.valor.toString().replace(",", "."));
            return response.data;
        } catch (error) {
            return 0;
        }
    };

    return { buscarValorServico };
}