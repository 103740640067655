import QRCode from "react-qr-code";
import { Logic } from "./logic";
import "./style.scss";
import { HeaderComponente } from "../../componentes/header.component";
import { FooterComponente } from "../../componentes/footer.component";

export default function Pix({ logic = Logic() }) {

  return (
    <>
      <main id="pix-page">
        <HeaderComponente configuration={logic.configuration} bgSolido={true} />
        <div className="container pt-2 pt-lg-5">

          {!logic.codeExpired ? 
            <div className="row mt-2 mt-lg-5">
              
              <div className="col">
                
                <h1 className={`${logic.pixName ? '' : 'shimmer'}`}>{logic.pixName ? `Código pix para o pagamento da sua filiação` : 'shimmer'}</h1>
                
                <p className={`info-pix ${logic.pixName ? '' : 'shimmer'}`}>
                  Abra o aplicativo do seu banco, entre na opção PIX, escolha pagar com o PIX copiar e colar, cole o código. Confirme o seu pagamento e pronto.
                </p>
                
                <div className="box-qrcode">
                  {logic.codigoCopiaCola ? 
                    <>
                    <QRCode
                      size={300}
                      className="qrCode"
                      value={logic.codigoCopiaCola}
                      viewBox={`0 0 300 300`}
                    />
                    </>
                  :
                    <div className="shimmer shimmer-qrcode"></div>
                  }
                </div>
                <div className="box-copy-code">
                  <button className={`btn ${!logic.copied ? 'btn-secondary' : 'btn-success'}`} onClick={logic.copyToClipboard}>{`${!logic.copied ? 'Copiar código' : 'Copiado!'}`}</button>
                </div>
              </div>

              <div className="col-auto d-none d-lg-block">
                <img src="/todos/ilustracao-pix-qrcode.svg" alt="Pix QrCode" />
              </div>

            </div>
            :
            <div className="row align-items-center mt-2 mt-lg-5">
              
              <div className="col">
                
                <h1 className="text-center">Código Pix expirado</h1>
                
              </div>

              <div className="col-auto d-none d-lg-block">
                <img src="/todos/ilustracao-pix-expired.svg" alt="Pix QrCode expired" />
              </div>

            </div>
          }

          {/* <div className="row mb-3">
            <div className="col text-center text-lg-start">
              <h3>Pix copia e cola</h3>
              <p className={`code-to-copy ${logic.codigoCopiaCola ? '' : 'shimmer'}`}>{logic.codigoCopiaCola ? logic.codigoCopiaCola : '--------------------------------------------------------------------------------------------------'}</p>
              <button className={`btn ${!logic.copied ? 'btn-secondary' : 'btn-success'}`} onClick={logic.copyToClipboard}>{`${!logic.copied ? 'Copiar código' : 'Copiado!'}`}</button>
            </div>
          </div> */}

        </div>
      </main>

      <footer>
        <FooterComponente configuration={logic.configuration} divideTela={false} />
      </footer>
    </>
  )
}
