import { Row, Col } from "react-bootstrap";
import { Configuration } from "../modelos";

export const FooterComponente = (props: { configuration: Configuration, divideTela: boolean }) => {
  return <Row className={"text-center w-100 m-0 footer" + (props.divideTela === false ? " bg-footer" : "")}>
    <Col sm={12}>
      <p className="pt-2 fw-normal text-muted">
        {props.configuration.footerText} - v{process.env.REACT_APP_VERSION}
      </p>
    </Col>
  </Row>
}