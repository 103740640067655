import { useLocation, useNavigate } from "react-router-dom";
import { Configuration } from "../../modelos";
import { useEffect, useState } from "react";
import { setEventPageView, dateComparePeriod } from "../../utils/utils";
import { CheckoutDTO } from "../../dto";

export const Logic = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [modalDiaNacionalSaudeShow, setmodalDiaNacionalSaudeShow] = useState(false);
    const [modalOutubroRosaShow, setmodalOutubroRosaShow] = useState(false);
    const [modalEsquentaBlackFridayShow, setmodalEsquentaBlackFridayShow] = useState(false);
    const [modalFiliacaoBlackFridayShow, setmodalFiliacaoBlackFridayShow] = useState(false);
    const [modalRefuturiza, setModalRefuturiza] = useState(false);
    const [modalBlackPosFiliacao, setModalBlackPosFiliacao] = useState(false);
    const [modalPosFiliacao, setModalPosFiliacao] = useState(false);

    const [modalEvents, setModalEvents] = useState(false);


    const configuration: Configuration = location.state !== null && (location.state as any).configuration !== null && (location.state as any).configuration !== undefined ? (location.state as any).configuration : new Configuration();
    const matricula: string = location.state !== null && (location.state as any).matricula !== null && (location.state as any).matricula !== undefined ? (location.state as any).matricula : "";
    const refuturizaCheckout: boolean = location.state !== null && (location.state as any).refuturizaCheckout !== null && (location.state as any).refuturizaCheckout !== undefined ? (location.state as any).refuturizaCheckout : "";
    const dto: CheckoutDTO = location.state !== null && (location.state as any).dto !== null && (location.state as any).dto !== undefined ? (location.state as any).dto : new CheckoutDTO();

    useEffect(() => {
        (document.getElementById("favicon") as any).href = configuration.favicon;
        setEventPageView(window.location.pathname);

        verifyOverflow();
        window.addEventListener('resize', verifyOverflow);
        modalSazonalidade();
      
        setModalEvents(true);     

        if (dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '08/04/2024' : '08/07/2024'}`, "08/11/2024").insidePeriod) {
            setModalEvents(true);
        }
    },[])

    if (location.state === null) {
        window.location.replace("/" + window.location.search);
    }

    const verifyOverflow = () => {
        if (window.innerWidth > 1199.98) {
            window.scrollTo(0, 0);
            document.body.classList.remove('overflow-auto');
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
            document.body.classList.add('overflow-auto');
        }
    }

    const modalSazonalidade = () => {
        if(dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '03/13/2024' : '03/14/2024'}`, "03/15/2024").insidePeriod){
            setModalPosFiliacao(true);
        }
}
    const handleSave = () => {
        navigate("/dependentes-sucesso", { state: { configuration } });
    }


    return {
        configuration,
        dto,

        modalDiaNacionalSaudeShow,
        setmodalDiaNacionalSaudeShow,

        modalOutubroRosaShow,
        setmodalOutubroRosaShow,

        modalEsquentaBlackFridayShow,
        setmodalEsquentaBlackFridayShow,

        modalFiliacaoBlackFridayShow,
        setmodalFiliacaoBlackFridayShow,

        modalShow,
        setModalShow,

        handleSave,
        matricula,

        modalRefuturiza,
        setModalRefuturiza,

        modalBlackPosFiliacao,
        setModalBlackPosFiliacao,
        
        modalPosFiliacao,
        setModalPosFiliacao,

        modalEvents, setModalEvents
    };
}