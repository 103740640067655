import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom';
import '../src/styles/main.scss';
import Routes from './routes';
import { CheckoutProvider } from './context/checkout.provider';
import * as Sentry from "@sentry/react";

var dsn = "https://a60f738c00dc48d999622776c0ecb3c5@o4505213032464384.ingest.sentry.io/4505229526368256"; // default solicite

if ( window.location.host.search('assine.') >= 0 )
  dsn = "https://fdde4d1ad1ed4ad69035027360d7d20c@o4505213032464384.ingest.sentry.io/4505229543800832";

Sentry.init({
  dsn: dsn,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://solicite.cartaodetodos.com.br", "https://assine.cartaodetodos.com.br", "https://solicite.dev.cartaodetodos.com.br", "https://assine.dev.cartaodetodos.com.br"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

declare global {
  interface Window {
    adyen: { encrypt: { createEncryption: any, createEncryptedForm: any } },
    dataLayer: any,
    fbq?: any,
    AdyenCheckout: any,
    btoa: any,
    ptag_params: any,
    grecaptcha: any,
  }
}

ReactDOM.render(
  <CheckoutProvider>
    <Routes />
  </CheckoutProvider>
    
  , document.getElementById('root'));

reportWebVitals();