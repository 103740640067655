import { CheckoutDTO } from "../dto";
import { Cep } from "../modelos";
import axiosInstance from "../utils/axiosInstance";

export const CepService = () => {
    const buscarCep = async (cep: string, data: CheckoutDTO) => {
        try {
            let response = await axiosInstance.post<Cep, any>(`/validate/cep/${cep}`, JSON.stringify(data));
            return response.data;
        } catch (error: any) {
            let response = error.response.data;
            response.status = error.response.status;
            response.success = false;
            return response;
        }
    };

    return { buscarCep };
}