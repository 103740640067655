import { FocusEventHandler } from 'react';
import { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
declare type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

const CheckboxComponent = (props: { title: string, onChange?: React.ChangeEventHandler<FormControlElement>, onBlur?: FocusEventHandler, disabled?: boolean, isInvalid?: boolean, required?: boolean, value: boolean, errorMessage?: string, customLabel?: ReactElement }) => {
    const { title, onChange, isInvalid, disabled, value, required, errorMessage, onBlur, customLabel } = props;

    return (
        <Form.Group className="mb-1">
            <Form.Check type='checkbox' id={title}>
                <Form.Check.Input
                    id={title}
                    checked={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    isInvalid={isInvalid ?? false}
                    disabled={disabled ?? false}
                    required={required ?? false} />
                <Form.Check.Label>{customLabel ? customLabel : title}{required ? "*" : ""}</Form.Check.Label>
                <Form.Control.Feedback className="text-start" type="invalid">{errorMessage ?? "Valor obrigatório"}</Form.Control.Feedback>
            </Form.Check>
        </Form.Group>
    );
};

export default CheckboxComponent;