import axiosInstance from "../utils/axiosInstance";

export const MarketingConversionService = () => {
    const sendData = async (payload: any) => {
        try {
            var response = await axiosInstance.post<any>(`/sendmarketingconversion`, JSON.stringify(payload));
            return response.data;
        } catch (error: any) {
            return error.response.data;
        }
    };

    return { sendData };
}