import { Modal, Row, Col, Button } from "react-bootstrap";
import { ReactComponent as IconFechar } from "../assets/icons/icon-close.svg";

export const ModalRefiliacaoCepComponent = (props: any) => {
  return (
    <Modal
      {...props}
      size="xl"
      className="modal-refiliacao"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      {/* <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header> */}

      <Modal.Body className="refiliacao-cep">
        <Row className="align-items-center">
          <Col className="col-auto d-xl-none pt-4">
            <img src="/todos/refiliacao/cep/ilustrator-mobile.png" alt="Refiliação" />
          </Col>
          <Col className="px-2 px-md-0">
            <h1 className="text-text-link pt-4 pt-md-0">
              Verificamos em nosso <br className="d-block d-xl-none" /> sistema
              {' '}
              <br className="d-none d-xl-block" />
              que você se encontra em um
              <br className="d-none d-xl-block" />
              {' '} novo <br className="d-block d-xl-none" /> endereço.
            </h1>
            <p>
              Para realizar a atualização de unidade
              <br className="d-none d-xl-block" />
              {' '}  recomendamos que entre em contato via {' '}
              <br className="d-none d-xl-block" />
              telefone ou na franquia mais próxima.
            </p>

            <Col className="col-auto d-xl-pt-4 pt-2">
              <Button
                // onClick={props.onHide}
                href="tel:08002838916"
                target="_blank"
                variant="secondary" size="lg">
                Falar com um atendente
              </Button>
            </Col>

            <Col className="col-auto d-xl-pt-4 pt-4">
              <Button
                href="https://www.cartaodetodos.com.br/nossas-unidades/"
                target="_blank"
                variant="secondary" size="lg">
                Unidade mais próxima
              </Button>
            </Col>


          </Col>
          <Col className="col-auto  d-none d-xl-block">
            <img src="/todos/refiliacao/cep/ilustrator.png" alt="Refiliação" />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
