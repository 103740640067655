import { Modal } from "react-bootstrap";
import { ReactComponent as IconFechar } from "../assets/icons/icon-close.svg";

export const ModalTermosComponente = (props: any) => {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen
    >
      <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src="https://adesao.cartaodetodos.com.br/assets/terms/checkout-termos-condicoes.pdf"
          width="100%"
          height="1000px"
          title="Termos e Condições"
          style={{ border: "none" }}
        />
      </Modal.Body>
    </Modal>
  );
};
