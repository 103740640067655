import { useState } from "react";
import { Modal, Button, Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { ReactComponent as IconFechar } from '../assets/icons/icon-close.svg';
import { CheckoutDTO } from "../dto";
import { LeadService } from "../servicos";

export const ModalEsquentaBlackFridayComponent = (props: any) => {

    const [isAcceptBlackFriday, setIsAcceptBlackFriday] = useState(false);
    const [loading, setLoading] = useState(false);

    function getForm() {
        return {
            nome: props.dto.nome,
            cpf: props.dto.cpf,
            email: props.dto.email,
            telefone: props.dto.telefone,
            termos: props.dto.termos,
            termosSmsEmail: props.dto.termosSmsEmail,
            cep: props.dto.cep,
            franquiaId: props.dto.franquiaId,
            rua: props.dto.rua,
            numero: props.dto.numero,
            complemento: props.dto.complemento,
            bairro: props.dto.bairro,
            cidade: props.dto.cidade,
            estado: props.dto.estado,
            black_friday_2022: true
        } as CheckoutDTO;
    }

    async function sendOptIn() {
        setLoading(true);
        var form = getForm();
        // if ( window.location.host.search('solicite.') < 0 ) //TODO: Regra checkout multi url
            await LeadService().sendLead(form);
        setLoading(false);
    }


    return (
        <Modal
            {...props}
            className="modal-esquenta-black-friday"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Container className="p-0">
                    <Row className="close justify-content-end">
                        <Col className="d-flex align-items-center justify-content-end pointer" onClick={props.onHide}>
                            <strong className="text-white me-2">Fechar</strong>
                            <IconFechar stroke="white" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center ">
                        <Col className="col-auto">
                            <picture>
                                <source media="(max-width: 1199.98px)" srcSet="/todos/banner-esquenta-mobile.jpg" />
                                <source media="(min-width: 1200px)" srcSet="/todos/banner-esquenta-desktop.jpg" />
                                <img src="/todos/banner-esquenta-desktop.jpg" alt="Esquenta Black Friday"/>
                            </picture>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body>

                {
                    isAcceptBlackFriday && !loading ? (
                        <>
                            <Row>
                                <Col>
                                    <h2 className="text-primary px-2 pt-2 text-center">
                                        Agora, só falta você buscar seu Cartão de TODOS e chip!
                                    </h2>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="pt-2 text-center">
                                        Acesse nossa página de unidades  <br className="d-xl-none" /> <strong> Cartão de TODOS!</strong> <br className="" />
                                        Insira seu CEP e veja qual a unidade mais próxima <br className="d-xl-none" /> do seu endereço para você fazer sua retirada. <br className="d-xl-none" /> 
                                        Por lá, você consulta o endereço e o telefone da unidade. Se preferir, ligue para ver a  <br className="d-xl-none" />  disponibilidade do chip.
                                    </p>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col className="col-auto">
                                    <Button
                                        href="https://cartaodetodos.com.br/buscar-descontos/?utm_source=site&utm_campaign=esquenta-black-friday-2022&utm_medium=modal-site"
                                        target="_blank" variant="secondary" size="lg" className="m-0">
                                        Buscar unidade
                                    </Button>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="text-muted fst-italic text-center h5 fw-light pt-3">
                                        * Cartão de descontos não é plano de saúde. <br className="d-xl-block d-none"/>
                                        ** Oferta válida para novos filiados Cartão de TODOS, limitada a 1 chip por titular. Para <br className="d-xl-block d-none"/>
                                        novas recargas será cobrado o valor de R$ 19,90.
                                    </p>
                                </Col>
                            </Row>

                        </>
                    ) : (
                        <>
                            <Row>
                                <Col>
                                    <h2 className="text-primary lg-px-lg-4 pt-2 text-center">
                                        Aproveite agora a promoção Esquenta Black Friday:  pediu Cartão de TODOS, levou chip de celular
                                    </h2>
                                </Col>
                            </Row>

                            <Row className="justify-content-center px-1">                               
                                <Col className="col-auto lg-px-5 position-relative">
                                    <Form.Group className="lg-px-5 lg-m-3 m-2 pt-2">
                                        <Form.Check type='checkbox' id="blackfriday">
                                            <Form.Check.Input
                                                id="blackfriday"
                                                value={isAcceptBlackFriday.toString()}
                                                onChange={() => setIsAcceptBlackFriday(true)}
                                                onClick={() => sendOptIn()}
                                                disabled={loading}
                                                required />
                                            <Form.Check.Label className="fw-normal h5">
                                                Desejo participar da campanha Esquenta Black Friday <br className="d-xl-block d-none" />
                                                do Cartão de TODOS e receber um chip telefônico  <br className="d-xl-block d-none" />
                                                pré-pago 10 GB da SURF
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </Form.Group>
                                    {loading ? 
                                    <Spinner animation="border" role="status" variant="primary" className="loading position-absolute">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    :
                                    <></>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="m-0 p-0">
                                    <p className="text-muted fst-italic text-center h5 fw-light pt-2">
                                        * Cartão de descontos não é plano de saúde.<br className="d-xl-block d-none" />
                                        ** Oferta válida para novos filiados Cartão de TODOS, limitada a 1 chip por titular. Para  <br className="d-xl-block d-none" />
                                        novas recargas será cobrado o valor de R$ 19,90.
                                    </p>
                                </Col>
                            </Row>

                        </>
                    )
                }
            </Modal.Body>
        </Modal>
    )
}
