import { AddPaymentInfoProps, AdicionaisCheckoutProps, BeginCheckoutProps, DatalayerPurchaseProps, ShippingInfoProps } from "../dto/datalayer.dto";

export function DataLayerBeginCheckout({ optInSms, formPhone, formEmail, price, idpv }: BeginCheckoutProps) {
  let dataLayer = window.dataLayer || []
  dataLayer.push({
    event: 'begin_checkout',
    optInSms: optInSms,
    formPhone: formPhone,
    formEmail: formEmail,
    idpv: idpv,
    ecommerce: {
      items: [
        {
          item_name: 'Filiação Cartão de Todos',
          item_id: 1,
          price: price,
          item_brand: 'CDT Site',
          quantity: 1
        }
      ]
    },
  });
}

export function DatalayerShippingInfo({ price, cep, idpv }: ShippingInfoProps) {
  let dataLayer = window.dataLayer || []
  dataLayer.push({
    event: 'add_shipping_info',
    formCep: cep,
    idpv: idpv,
    ecommerce: {
      items: [
        {
          item_name: 'Filiação Cartão de Todos',
          item_id: 1,
          price: price,
          item_brand: 'CDT Site',
          quantity: 1
        }
      ]
    },
  });
}

export function DataLayerCepUnavailable() {
  let dataLayer = window.dataLayer || []
  dataLayer.push({
    event: "cep_unavailable",
  });
}

export function DatalayerAddPaymentInfo({ price, idpv, isRefuturiza, isEnergiaTodos }: AddPaymentInfoProps) {
  let dataLayer = window.dataLayer || []
  let prepareData: any = {
    event: 'add_payment_info',
    idpv: idpv,
    ecommerce: {
      items: [
        {
          item_name: 'Filiação Cartão de Todos',
          item_id: 1,
          price: price,
          item_brand: 'CDT Site',
          quantity: 1
        }
      ]
    },
  };
  if (isRefuturiza) {
    prepareData.ecommerce.items.push(
      {
        item_name: 'Refuturiza',
        item_id: 99998,
        price: '0,01',
        item_brand: 'CDT Checkout',
        quantity: 1
      },
    )
  }
  if (isEnergiaTodos) {
    prepareData.ecommerce.items.push(
      {
        item_name: 'Energia de Todos',
        item_id: 99999,
        price: '0,01',
        item_brand: 'CDT Checkout',
        quantity: 1
      },
    )
  }
  dataLayer.push(prepareData);
}

export function DatalayerPurchase({ cpf, email, phone, tid, total, method_payment, voucher, price, idpv, isRefuturiza, isEnergiaTodos }: DatalayerPurchaseProps) {
  var dataLayer = window.dataLayer || []

  let prepareData: any = {
    event: 'purchase',
    orderDocument: cpf,
    orderEmail: email,
    orderPhone: phone,
    idpv: idpv,
    ecommerce: {
      transaction_id: tid,
      affiliation: 'CDT Checkout',
      value: total,
      tax: '0',
      shipping: '0',
      currency: 'BRL',
      payment_type: method_payment,
      coupon: voucher,
      items: [
        {
          item_name: 'Filiação Cartão de Todos',
          item_id: 1,
          price: price,
          item_brand: 'CDT Checkout',
          quantity: 1,
          coupon: voucher,
        }
      ]
    }
  };
  if (isRefuturiza) {
    prepareData.ecommerce.items.push(
      {
        item_name: 'Refuturiza',
        item_id: 99998,
        price: '0,01',
        item_brand: 'CDT Checkout',
        quantity: 1,
        coupon: voucher
      },
    )
  }
  if (isEnergiaTodos) {
    prepareData.ecommerce.items.push(
      {
        item_name: 'Energia de Todos',
        item_id: 99999,
        price: '0,01',
        item_brand: 'CDT Checkout',
        quantity: 1,
        coupon: voucher
      },
    )
  }
  dataLayer.push(prepareData);
}

export function DataLayerError(idpv) {
  let dataLayer = window.dataLayer || []
  dataLayer.push({
    event: "payment_error",
    orderMessage: "Erro ao processar o pagamento, verifique os dados do cartão",
    orderStatusCode: "404",
    orderDescription: "not found",
    'idpv': idpv,
  });
}

export function DataLayerRefiliacao() {
  let dataLayer = window.dataLayer || []
  dataLayer.push({
    event: "registration_update",
  });
}

export function DataLayerAdicionais({ optInSms, formPhone, formEmail, price, voucher, isRefuturiza, isEnergiaTodos }: AdicionaisCheckoutProps) {
  let dataLayer: any = window.dataLayer || [];
  let prepareData: any = {
    event: "add_to_cart",
    optInSms: optInSms,
    formPhone: formPhone,
    formEmail: formEmail,
    ecommerce: {
      items: [
        {
          item_name: 'Filiação Cartão de Todos',
          item_id: 1,
          price: price,
          item_brand: 'CDT Checkout',
          quantity: 1,
          coupon: voucher,
        },
      ]
    },
  };
  if (isRefuturiza) {
    prepareData.ecommerce.items.push(
      {
        item_name: 'Refuturiza',
        item_id: 99998,
        price: '0,01',
        item_brand: 'CDT Checkout',
        quantity: 1,
        coupon: voucher
      },
    )
  }
  if (isEnergiaTodos) {
    prepareData.ecommerce.items.push(
      {
        item_name: 'Energia de TODOS',
        item_id: 99999,
        price: '0,01',
        item_brand: 'CDT Checkout',
        quantity: 1,
        coupon: voucher
      },
    )
  }
  dataLayer.push(prepareData);
}
