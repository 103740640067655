import { ReactComponent as IconGooglePlay } from '../assets/icons/google-play.svg';
import { ReactComponent as IconAppStore } from '../assets/icons/appstore.svg';
import { ReactComponent as IconFacebook } from '../assets/icons/icon-facebook.svg';
import { ReactComponent as IconWhatsapp } from '../assets/icons/icon-whatsapp.svg';
import { ReactComponent as IconEmail } from '../assets/icons/icon-mail.svg';
import { Row, Col, Container } from "react-bootstrap";
import { Configuration } from '../modelos';

export const MobileDownloadsComponente = (props: { configuration: Configuration }) => {
    return <Container>
        <Row className="pt-3">
            <Col>
                <h5 className="fw-normal text-center mb-3">Indique o <span className="fw-bold">{props.configuration.appName}</span> para<br />os seus amigos!</h5>
            </Col>
        </Row>
        <Row className="justify-content-center w-100 py-3 m-0">
            <div className="card-icon mx-2 col-auto">
                <a href={props.configuration.utilsLinks?.facebook} target="_blank">
                    <IconFacebook />
                </a>
            </div>
            <div className="card-icon mx-2 col-auto">
                <a href={props.configuration.utilsLinks?.whatsapp} target="_blank">
                    <IconWhatsapp />
                </a>
            </div>
            <div className="card-icon mx-2 col-auto">
                <a href={props.configuration.utilsLinks?.email} target="_blank">
                    <IconEmail className="fill-primary" />
                </a>
            </div>
        </Row>
        <Row>
            <Col className='my-3'>
                <h5 className="fw-normal text-center text-default">Marque consultas com mais facilidade <br/>pelo nosso app!</h5>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col className="text-end">
                <a href={props.configuration.utilsLinks?.googleplay} target="_blank">
                    <IconGooglePlay className="pointer" />
                </a>
            </Col>
            <Col className="text-start">
                <a href={props.configuration.utilsLinks?.appstore} target="_blank">
                    <IconAppStore className="pointer" />
                </a>
            </Col>
        </Row>
    </Container>
}
