import axios from "axios";
import { Gt7CepInvalid } from "../modelos";

export const AnalyticsService = () => {
  const SendAnalytics = async ( data: any) => {
    try {
      var response = await axios.post(`${process.env.REACT_APP_GT7_API}/analytics/event`, JSON.stringify(data));
      return response;
    } catch (error) {
      return undefined;
    } 
  };

  const SendInvalidCep = async ( data: Gt7CepInvalid) => {
    try {
      var response = await axios.post(`${process.env.REACT_APP_GT7_API}/analytics/invalid/cep`, JSON.stringify(data));
      return response;
    } catch (error) {
      return undefined;
    } 
  };
  return { SendAnalytics, SendInvalidCep };
}
