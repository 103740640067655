import {useState} from "react";
import { Modal, Container, Row, Col, Button, Carousel } from "react-bootstrap";
import { ReactComponent as IconFechar } from "../assets/icons/icon-close.svg";
import { dateComparePeriod } from "../utils/utils";

export const ModalDiaDoConsumidorPosFiliacaoComponent = (props: any) => {
  const [indexCarousel, setIndexCarousel] = useState(0);

  return (
    <Modal
      {...props}
      size="xl"
      className="modal-diadoconsumidor-posfililacao"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      {/* <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header> */}

      <Modal.Body>
        <Carousel className="text-center pt-1" slide={false} controls={false} indicators={false} activeIndex={indexCarousel}>

          <Carousel.Item interval={600000}>
            <Row className="align-items-center">
              <Col className="col-auto mx-auto order-0 order-xl-1">
                <picture>
                    <source media="(max-width: 1199.98px)" srcSet="/todos/blackfriday-time-coracao-mobile.png" width={147} height={150} />
                    <source media="(min-width: 1200px)" srcSet="/todos/blackfriday-time-coracao.png" />
                    <img src="/todos/blackfriday-time-coracao.png" alt="Time do coração" width={403} height={412} />
                </picture>
              </Col>
              <Col className="order-1 order-xl-0">
                <h1 className="text-center text-xl-start">
                  Como resgatar a carteirinha do{" "}
                  <br className="d-none d-xl-block" />
                  seu time gratuita
                </h1>
                <p className="text-center text-xl-start">
                  Acesse nossa página de seleção times, <br className="d-block d-xl-none" />clique em aceite{" "}
                  <br className="d-none d-xl-block" />
                  e pronto, agora já pode <br className="d-block d-xl-none" />usar todos benefícios. 
                </p>

                <div className="text-center text-xl-start">
                  <a href={`https://campanhas.cartaodetodos.com.br/carnaval/onboarding-times/?data=${props.data}`} target="_blank" className='btn btn-secondary btn-blackfriday'>Selecionar meu time</a>
                </div>

                <div className="text-center text-xl-start">
                  <button className="btn btn-outline-light btn-proximo" onClick={() => setIndexCarousel(1)}>Próximo passo</button>
                </div>
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item interval={600000}>
            <Row className="align-items-center">
              <Col className="col-auto mx-auto order-0 order-xl-1">
                <picture>
                    <source media="(max-width: 1199.98px)" srcSet="/todos/blackfriday-cashback-mobile.png" width={150} height={160} />
                    <source media="(min-width: 1200px)" srcSet="/todos/blackfriday-cashback.png" />
                    <img src="/todos/blackfriday-cashback.png" alt="Cashback" width={384} height={412} />
                </picture>
              </Col>
              <Col className="order-1 order-xl-0">
                <h1 className="text-center text-xl-start">
                  Quero ganhar R$ 29,70 de <br className="d-block d-xl-none" />cashback
                </h1>
                <p className="text-center text-xl-start">
                  Para resgatar o dinheiro, basta baixar nosso app e criar{" "}
                  <br className="d-none d-xl-block" />
                  sua conta, tudo pronto para realizar suas consultas{" "}
                  <br className="d-none d-xl-block" />
                  e exames
                </p>

                <div className="text-center text-xl-start">
                  <a href="https://linktr.ee/appcartaodetodos" target="_blank" className='btn btn-secondary btn-blackfriday'>Baixar meu app</a>
                </div>

                <div className="text-center text-xl-start">
                  <button className="btn btn-outline-light btn-proximo" onClick={() => setIndexCarousel(2)}>Próximo passo</button>
                </div>
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item interval={600000}>
            <Row className="align-items-center">
              <Col className="col-auto mx-auto order-0 order-xl-1">
                <picture>
                    <source media="(max-width: 1199.98px)" srcSet="/todos/blackfriday-refuturiza-mobile.png" width={155} height={150} />
                    <source media="(min-width: 1200px)" srcSet="/todos/blackfriday-refuturiza.png" />
                    <img src="/todos/blackfriday-refuturiza.png" alt="Blackfriday Refuturiza" width={427} height={412} />
                </picture>
              </Col>
              <Col className="order-1 order-xl-0">
                <h1 className="text-center text-xl-start">
                  2 meses de cursos{" "}
                  <br className="d-block d-xl-none" />
                  gratuitos
                </h1>
                <p className="text-center text-xl-start">
                  Acesse a Refuturiza e utilize o <br className="d-block d-xl-none" /> usuário e senha{" "} 
                  <br className="d-none d-xl-block" />
                  que foram enviados para o seu e-mail!
                </p>

                <div className="text-center text-xl-start">
                  <a href="https://refuturiza.com.br/aluno/acessar" target="_blank" className='btn btn-secondary btn-blackfriday'>Acessar a Refuturiza</a>
                </div>

                <div className="text-center text-xl-start">
                  <button className='btn btn-outline-light btn-proximo' onClick={props.onHide}>Minha matricula</button>
                </div>

              </Col>
            </Row>
          </Carousel.Item>

          <div className="carousel-indicators">
            <button type="button" data-bs-target="" className={indexCarousel === 0 ? 'active': ''} onClick={() => setIndexCarousel(0)}></button>
            <button type="button" data-bs-target="" className={indexCarousel === 1 ? 'active': ''} onClick={() => setIndexCarousel(1)}></button>
            <button type="button" data-bs-target="" className={indexCarousel === 2 ? 'active': ''} onClick={() => setIndexCarousel(2)}></button>
          </div>

        </Carousel>
      </Modal.Body>
    </Modal>
  );
};
