import axiosInstance from "../utils/axiosInstance";

export const PixService = () => {
    const generateQrCode = async (processo: string, recorrencia: boolean) => {
        const data: any = {
            processo,
            recorrencia
        }
        try {
            var response = await axiosInstance.post<any>(`/generate/pixqrcode`, data);
            return response.data;
        } catch (error) {
            return undefined;
        }
    };

    return { generateQrCode };
}