import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as IconFechar } from '../assets/icons/icon-close.svg';

export const ModalDiaNacionalSaudeComponente = (props: any) => {
    return (
        <Modal
            {...props}
            className="modal-sazonal"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Container className="p-0">
                    <Row className="close justify-content-end">
                        <Col className="d-flex align-items-center justify-content-end pointer" onClick={props.onHide}>
                            <strong className="text-white me-2">Fechar</strong>
                            <IconFechar stroke="white" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="col-auto">
                            <picture>
                                <source media="(max-width: 1024px)" srcSet="/todos/dia-nacional-saude-mobile.png"/>
                                <source media="(min-width: 1025px)" srcSet="/todos/dia-nacional-saude-desktop.png"/>
                                <img src="/todos/dia-nacional-saude-desktop.png" alt="Dia nacional da saúde." />
                            </picture>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <h2 className="title">
                                Aproveite essa oferta e agende o seu check-up:
                            </h2>
                            <p className="description">
                                Consulta com <b>Clínico Geral + Exames</b> por apenas <b>R$ 99</b>. Com o número da sua matrícula ou CPF,
                                <br className="d-none d-lg-block"/>
                                você já pode agendar a sua consulta no site das <b>clínicas AmorSaúde</b>.
                            </p>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col className="col-auto">
                            <Button href="https://amorsaude.com.br/" target="_blank" variant="secondary" size="lg">
                                Agendar Consulta
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <footer>
                                <i className="info">
                                    *Condição válida de 1 até 5/8, para filiados Cartão de TODOS. Para utilizar o benefício, a consulta com 
                                    <br className="d-none d-lg-block"/>
                                    clínico geral deverá ser realizada até 31/8.
                                </i>
                            </footer>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}
