import { CheckoutDTO } from "../dto";
import { Voucher } from "../modelos";
import axiosInstance from "../utils/axiosInstance";

export const VoucherService = () => {
    const buscarVoucher = async (voucher: string, data: CheckoutDTO) => {
        try {
            var response = await axiosInstance.post<Voucher>(`/validate/voucher/${voucher}`, JSON.stringify(data));
            return response.data;
        } catch (error) {
            return undefined;
        }
    };

    return { buscarVoucher };
}