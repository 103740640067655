import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import { ReactComponent as IconFechar } from "../assets/icons/icon-close.svg";
import { dateComparePeriod } from "../utils/utils";

export const ModalLoading3DS = (props: any) => {
  return (
    <Modal
      {...props}
      size="xl"
      className="modal-3ds"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      backdropClassName="reload-3ds"	
      keyboard={false}
      centered
    >
      {/* <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header> */}

      <Modal.Body className="default">
        <Row className="align-items-center d-flex justify-content-center">
          <Col className="col-auto pt-2">
            <img className="gif" src="/todos/3ds/loading-mobile.gif" alt="loading" />
          </Col>

          <Col className="col-auto pt-4">
            <h1 className="d-flex justify-content-center text-center">
              Estamos processando, <br className="d-xl-none" /> tempo estimado:
            </h1>

            <div className="progress-area">
              <div className="progress-container">
                <div className="progress-bar" style={{width: `${props.percentLoading}%`}}>
                  <div className="progress-circle">
                    <img className="progress-img" src="/todos/3ds/bolinha.png" alt="bolinha de loading" />
                  </div>
                </div>
              </div>
            </div>

          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
