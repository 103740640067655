import { useEffect, useState } from "react";
import { Button, Navbar, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Configuration } from "../modelos";

export const HeaderComponente = (props: { configuration: Configuration, bgSolido: boolean, class?: string }) => {
    const navigate = useNavigate();
    const page = window.location.pathname === '/' ? 'inicio' : window.location.pathname.replace('/', '');

    const [logo, alterarLogo] = useState(props.configuration.logo);

    window.onresize = function (event) {
        verifyLogo();
    }

    useEffect(() => verifyLogo());

    const verifyLogo = () => {
        if (props.bgSolido === false) {
            if (window.innerWidth > 1199.98) {
                return alterarLogo(props.configuration.logo);
            }
            else {
                return alterarLogo(props.configuration.logoAlt);
            }
        } else {
            return alterarLogo(props.configuration.logo);
        }
    }

    return <>
        {process.env.REACT_APP_ENVIRONMENT === "dev" ?
            <Container fluid className="bg-danger text-center ps-md-3 ps-lg-5">
                <small className="text-white ps-lg-3">Site de Homologação</small>
            </Container> : <></>}
        <Navbar className={"px-1 px-xl-5 py-3 " + props.class + (props.bgSolido ? " bg-white" : "")} data-page={page} >
            <Container fluid="xxl">
                <Navbar.Brand>
                    <img
                        className="logo"
                        alt="Imagem Logo"
                        src={logo}
                        onClick={() => navigate("/")}
                        height="32" width="123"
                    />

                </Navbar.Brand>
                <a href="tel:08007292072"><Button variant="primary" type="button" className="btn-sm" title="Fale com nossos atendentes">0800 729 2072</Button></a>
            </Container>
        </Navbar></>
}
