import { Modal, Container, Row, Col, Button } from "react-bootstrap";
import { ReactComponent as IconFechar } from "../assets/icons/icon-close.svg";
import { dateComparePeriod } from "../utils/utils";

export const ModalRefiliacaoBlockComponent = (props: any) => {
  return (
    <Modal
      {...props}
      size="xl"
      className="modal-refiliacao"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      {/* <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header> */}

      {dateComparePeriod(`${process.env.REACT_APP_ENVIRONMENT === "dev" ? '11/01/2023' : '11/24/2023'}`, "11/27/2023").insidePeriod ?
        <Modal.Body className="blackfriday">
          <Row className="pl-4">
            <Col className="col-auto d-xl-none">
              <img
                src="/todos/refiliacao/blackfriday-mobile.png"
                alt="Refiliação"
              />
            </Col>
            <Col>
              <h1>
                Consultamos aqui e <br className="d-none d-xl-block" />
                você já foi nosso cliente
              </h1>
              <p>              
                <strong>Ótimo ter você de volta!</strong> Em alguns minutos{" "} 
                <br className="d-none d-xl-block" />
                um de nossos atendentes <br className="d-xl-none" /> entrará em{" "} 
                <br className="d-none d-xl-block" />
                contato via telefone com mais detalhes.
              </p>
            </Col>
            <Col className="col-auto d-none d-xl-block">
              <img src="/todos/refiliacao/blackfriday.png" alt="Refiliação" />
            </Col>
          </Row>
        </Modal.Body>
      :  
      <Modal.Body className="default">
        <Row className="align-items-center">
          <Col className="col-auto d-xl-none pt-4">
            <img src="/todos/ilustracao-refiliacao-mobile.png" alt="Refiliação" />
          </Col>
          <Col>
            <h1 className="text-text-link">
                Consultamos aqui e{' '}
                <br className="d-none d-xl-block" />
                você já foi nosso cliente
            </h1>
            <p>
              <strong>Ótimo ter você de volta!</strong> Em alguns minutos{" "}
              <br className="d-none d-xl-block" />
              um de nossos atendentes <br className="d-xl-none" /> entrará em{" "}
              <br className="d-none d-xl-block" /> contato por telefone.
            </p>
          </Col>
          <Col className="col-auto  d-none d-xl-block">
            <img src="/todos/ilustracao-refilacao.svg" alt="Refiliação" />
          </Col>
        </Row>
      </Modal.Body>
    }
    </Modal>
  );
};