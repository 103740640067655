import { useState } from "react";
import { Modal, Button, Container, Row, Col, Form, Spinner, Carousel } from "react-bootstrap";
import { ReactComponent as IconFechar } from '../assets/icons/icon-close.svg';
import { CheckoutDTO } from "../dto";
import { LeadService } from "../servicos";

export const ModalFiliacaoBlackFridayComponent = (props: any) => {

    return (
        <Modal
            {...props}
            className="modal-filiacao-black-friday"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Container className="p-0">
                    <Row className="close justify-content-end">
                        <Col className="d-flex align-items-center justify-content-end pointer" onClick={props.onHide}>
                            <strong className="text-white me-2">Fechar</strong>
                            <IconFechar stroke="white" />
                        </Col>
                    </Row>
                    <Row className="justify-content-center ">
                        <Col className="col-auto">
                            <picture>
                                <source media="(max-width: 1199.98px)" srcSet="/todos/black-friday-filiacao-mobile.jpg" />
                                <source media="(min-width: 1200px)" srcSet="/todos/black-friday-filiacao-desktop.jpg" />
                                <img src="/todos/black-friday-filiacao-desktop.jpg" alt="Esquenta Black Friday" />
                            </picture>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body>


                <Row>
                    <Col>
                        <Carousel className="carousel-blackfriday text-center pt-1" slide={false} >
                            <Carousel.Item interval={2200}>
                                <h2 className="text-primary lg-px-lg-4 text-center pt-2">
                                    Parabéns! Todas as vantagens da Black Real <br className="d-xl-block d-none" />
                                    já estão disponíveis para você!
                                </h2>

                                <p className="fw-bold pt-2">Plataforma Refuturiza com aulas de idiomas <br className="d-xl-none" />  + 1.400 cursos gratuitos por 1 mês.
                                    <span className="fw-normal"> Utilize o</span>
                                    <p className="fw-normal"> código promocional na hora da contratação no <br className="d-xl-none" />  site da Refuturiza: <span className="text-primary fw-bold">BLACK30</span></p>
                                </p>

                                <Col className="lg-pt-3">
                                    <a target="_blank" href="https://refuturiza.com.br/checkout/registrar?voucher=black30/?utm_source=modal-ativacao&utm_campaign=cdt-black-friday-2022&utm_medium=refuturiza-voucher" type="button" className="btn-alter btn border-1 text-bg-0800">Acessar Refuturiza</a>
                                </Col>
            
                                <Col className="footer-area">
                                    <p className="fw-normal text-black">Consulte mecânica e regulamento em: <a target="_blank" href="https://cartaodetodos.com.br/blackfriday/" className="btn-link text-black fw-normal">cartaodetodos.com.br/blackfriday.</a></p>
                                </Col>

                            </Carousel.Item>
                            <Carousel.Item interval={2200}>
                                <h2 className="text-primary text-center pt-2">
                                    Parabéns! Todas as vantagens da Black Real <br className="d-xl-block d-none" />
                                    já estão disponíveis para você!
                                </h2>

                                <p className="fw-bold pt-2">1 avaliação e limpeza odontológica.
                                    <span className="fw-normal"> Busque  <br className="d-xl-none" />  nossa clínica odontológica parceira mais próxima
                                        <br className="d-xl-block d-none" /> a você! Ligue e agende sua avaliação. Quando chegar à unidade para o atendimento, informe <br className="d-xl-block d-none" /> o código:</span>
                                    <span className="text-primary fw-bold"> <br className="d-xl-none" />  ODONTO</span>
                                </p>

                                <Col className="pt-3">
                                    <a href="https://www.amorsaude.com.br/unidades/?utm_source=modal-ativacao&utm_campaign=cdt-black-friday-2022&utm_medium=lp-amorsaude-unidades" type="button" target="_blank" className="btn-alter btn border-1 text-bg-0800">Buscar clínica</a>
                                </Col>

                                <Col className="footer-area">
                                    <p className="fw-normal text-black">Consulte mecânica e regulamento em: <a target="_blank" href="https://cartaodetodos.com.br/blackfriday/" className="btn-link text-black fw-normal">cartaodetodos.com.br/blackfriday.</a></p>
                                </Col>

                            </Carousel.Item>
                            <Carousel.Item interval={2200}>
                                <h2 className="text-primary lg-px-lg-4 text-center pt-2">
                                    E os benefícios da Black Real não param por aí!
                                </h2>

                                <div className="content">
                                    <p className="fw-bold pt-2">R$ 24 de cashback para 1 consulta com clínico geral.
                                        <span className="fw-normal">  Baixe nosso app e receba R$ 24 de saldo para uma consulta em até 30 dias nas Clínicas</span>
                                        <span className="text-black fw-bold"> AmorSaúde</span>
                                    </p>
                                </div>

                                <Col className="pt-3">
                                    <a href="https://www.bemvindo.cartaodetodos.com.br/baixeoapp/?utm_source=modal-ativacao&utm_campaign=black-friday-2022&utm_medium=baixar-app" target="_blank" type="button" className="btn-alter btn border-1 text-bg-0800">Baixar app</a>
                                </Col>

                                <Col className="footer-area">
                                    <p className="fw-normal text-black">Consulte mecânica e regulamento em: <a href="https://cartaodetodos.com.br/blackfriday/" target="_blank" className="btn-link text-black fw-normal">cartaodetodos.com.br/blackfriday.</a></p>
                                </Col>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
