import { useState } from "react";
import { Modal, Container, Row, Col, Button, Carousel } from "react-bootstrap";
import { ReactComponent as IconFechar } from "../assets/icons/icon-close.svg";
import { dateComparePeriod } from "../utils/utils";

export const ModalPosFiliacaoComponent = (props: any) => {
  const [indexCarousel, setIndexCarousel] = useState(0);

  return (
    <Modal
      {...props}
      size="xl"
      className="modal-blackfriday-posfililacao"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="justify-content-end">
        <div
          className="d-flex align-items-center pointer"
          onClick={props.onHide}
        >
          <strong className="text-white me-2">Fechar</strong>
          <IconFechar stroke="white" />
        </div>
      </Modal.Header>

      <Modal.Body>
        <Carousel className="text-center pt-1" slide={false} controls={false} indicators={false} activeIndex={indexCarousel}>

          <Carousel.Item interval={600000}>
            <Row className="align-items-center pt-1">
              <Col className="col-auto mx-auto order-0 order-xl-1 pt-4">
                <picture>
                  <source media="(max-width: 1199.98px)" srcSet="/todos/2024/campanhas/zico/modal/person.png" width={310} height={150} />
                  <source media="(min-width: 1200px)" srcSet="/todos/2024/campanhas/zico/modal/person.png" />
                  <img src="/todos/2024/campanhas/zico/modal/person.png" alt="Time do coração" width={424} height={413} />
                </picture>
              </Col>
              <Col className="order-1 order-xl-0">
                <h1 className="text-center text-xl-start">
                  Participe da nossa promoção!
                </h1>
                <p className="text-center text-xl-start">
                  Acesse o site, preencha seus dados e concorra a muitos prêmios!
                </p>

                <div className="text-center text-xl-start">
                  <a
                    href={`https://promocartaodetodos.com.br/?utm_source=checkout-cdt&utm_campaign=golacodepremios&utm_medium=promocao`}
                    target="_blank"
                    className='btn btn-secondary btn-blackfriday' rel="noreferrer">Participar agora!</a>
                </div>

                <div className="text-center text-xl-start">
                  <button className="btn btn-outline-light btn-proximo" onClick={() => setIndexCarousel(1)}>Próximo passo</button>
                </div>
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item interval={600000}>
            <Row className="align-items-center pt-1">
              <Col className="col-auto mx-auto order-0 order-xl-1 pt-4">
                <picture>
                  <source media="(max-width: 1199.98px)" srcSet="/todos/2024/campanhas/zico/modal/person.png" width={210} height={150} />
                  <source media="(min-width: 1200px)" srcSet="/todos/2024/campanhas/zico/modal/person.png" />
                  <img src="/todos/2024/campanhas/zico/modal/person.png" alt="Cashback" width={424} height={413} />
                </picture>
              </Col>
              <Col className="order-1 order-xl-0">
                <h1 className="text-center text-xl-start">
                  Resgate agora sua carteirinha do seu time
                </h1>
                <p className="text-center text-xl-start">
                  Acesse nossa página de seleção times, clique em aceite e pronto, agora já pode usar todos benefícios. {" "}
                </p>

                <div className="text-center text-xl-start">
                  <a href={`https://campanhas.cartaodetodos.com.br/golacodepremios/onboarding-times/?data=${props.data}`}
                    target="_blank"
                    className='btn btn-secondary btn-blackfriday' rel="noreferrer">
                    Quero participar!
                  </a>
                </div>

                <div className="text-center text-xl-start">
                  <button className="btn btn-outline-light btn-proximo" onClick={() => setIndexCarousel(2)}>Próximo passo</button>
                </div>
              </Col>
            </Row>
          </Carousel.Item>

          <Carousel.Item interval={600000}>
            <Row className="align-items-center pt-1">
              <Col className="col-auto mx-auto order-0 order-xl-1 pt-4">
                <picture>
                  <source media="(max-width: 1199.98px)" srcSet="/todos/2024/campanhas/zico/modal/person.png" width={210} height={150} />
                  <source media="(min-width: 1200px)" srcSet="/todos/2024/campanhas/zico/modal/person.png" />
                  <img src="/todos/2024/campanhas/zico/modal/person.png" alt="Blackfriday Refuturiza"width={424} height={413} />
                </picture>
              </Col>
              <Col className="order-1 order-xl-0">
                <h1 className="text-center text-xl-start">
                  AllpHome + Refuturiza
                </h1>
                <p className="text-center text-xl-start">
                  Plataforma <strong>AllpHome</strong> para se exercitar 
                  <br className="d-block d-xl-none" /> 
                  e acesso de 30 dias gratuitos na plataforma da <strong> Refuturiza</strong>
                </p>

                <div className="text-center text-xl-start">
                  <a
                    href="https://allpfithome.com.br/cdt/?utm_source=checkout-cdt&utm_campaign=golacodepremios&utm_medium=promocao"
                    target="_blank"
                    className='btn btn-secondary btn-blackfriday' rel="noreferrer">
                    Acesse AllpHome
                  </a>
                </div>

                <div className="text-center text-xl-start">
                  <a
                    href="https://refuturiza.com.br/?utm_source=checkout-cdt&utm_campaign=golacodepremios&utm_medium=promocao"
                    target="_blank"
                    className='btn btn-secondary btn-blackfriday' rel="noreferrer">
                    Acesse Refuturiza
                  </a>
                  {/* <button className='btn btn-outline-light btn-proximo' onClick={props.onHide}>Minha matricula</button> */}
                </div>

              </Col>
            </Row>
          </Carousel.Item>

          <div className="carousel-indicators">
            <button type="button" data-bs-target="" className={indexCarousel === 0 ? 'active' : ''} onClick={() => setIndexCarousel(0)}></button>
            <button type="button" data-bs-target="" className={indexCarousel === 1 ? 'active' : ''} onClick={() => setIndexCarousel(1)}></button>
            <button type="button" data-bs-target="" className={indexCarousel === 2 ? 'active' : ''} onClick={() => setIndexCarousel(2)}></button>          
          </div>

        </Carousel>

        <div className="cards d-xl-block">
          <img src="/todos/2024/campanhas/zico/modal/cartoes.png" alt="Cartão de times" className="img-fluid" width={799} height={'auto'} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
