import axios from "axios";
import { ThreeDSModel } from "../dto/threeDS.dto";

export class ThreeDSModelRefund {
    cpf: string | undefined;
    gateway: string | undefined;
    tid: string | undefined;
}

export const ThreeDsService = () => {
    
    const get3DS2 = async (data: ThreeDSModel) => {
        try {
            var response = await axios.post<any>(`${process.env.REACT_APP_API}/3DS2`, JSON.stringify(data));
            return response.data;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    const get3DS2Refund = async (data: ThreeDSModelRefund) => {
        try {
            var response = await axios.post<ThreeDSModelRefund>(`${process.env.REACT_APP_API}/3DS2Refund`, JSON.stringify(data));
            return response.data;
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    return { get3DS2, get3DS2Refund };
}