import { Logic } from "./logic";
import "./style.scss";

export default function NotFound({ logic = Logic() }) {

  return (
    <>
      <main>
        <h1>Erro 404</h1>
      </main>
    </>
  )
}
