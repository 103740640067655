import { ReactComponent as IconPhone } from '../../assets/icons/icon-phone.svg';
import { ReactComponent as IconCheckAll } from '../../assets/icons/icon-check-all.svg';
import { ReactComponent as IconReload } from '../../assets/icons/icon-reload.svg';
import { MetodoPagamentoComponente } from '../../componentes/metodo_pagamento';
import { FooterComponente } from "../../componentes/footer.component";
import { HeaderComponente } from "../../componentes/header.component";
import { Col, Container, Row } from "react-bootstrap"
import React, { useEffect } from "react";
import { Logic } from "./logic";
import "./style.scss";
import ReCAPTCHA from "react-google-recaptcha";

export default function Analise({ logic = Logic() }) {

  return (
    <>
      {logic.showPayment ?
        <ReCAPTCHA
          ref={logic.recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        />
        :
        <></>
      }
      <main>
        <HeaderComponente configuration={logic.configuration} bgSolido={true} />
        <Container className="px-5">
          <Row className="h-responsive align-items-start align-items-lg-center analise-page">
            <div className='col'>
              <Row>
                <Col sm={12}>
                  { logic.paymentAttempts === 0 ?
                    <>
                      <h2>
                        Ops! Não conseguimos realizar o pagamento 
                        <br />
                        no seu cartão.
                      </h2>
                      <h5 className='fw-normal pt-2'>Que tal revisar os dados de pagamento?</h5>
                    </>
                    :
                    <>
                      <h2>
                        Ainda não conseguimos processar o 
                        <br />
                        pagamento.
                      </h2>
                      <h5 className='fw-normal pt-2'>Vamos tentar com outro cartão ou outra forma de pagamento?</h5>
                    </>
                  }
                </Col>
                <Col sm={12} className="mt-5">
                  {/* <a
                    href="tel:08007292072"
                    className="btn btn-secondary btn-lg my-4 px-4 btn-contact"
                    title="tel:08007292072"
                  >
                    Fale com a gente <IconPhone className="ms-1 mt-n1" />
                  </a> */}

                  {logic.paymentAttempts === 0 && !logic.showPayment ?
                    <button className="btn btn-secondary btn-sm" onClick={() => logic.alterarShowPayment(true)}>
                      Revisar dados de pagamento <IconCheckAll className="ms-1 mt-n1" />
                    </button>
                    :
                    <></>
                  }

                  {logic.paymentAttempts === 1 && !logic.showPayment ?
                    <button className="btn btn-secondary btn-sm" onClick={() => logic.alterarShowPayment(true)}>
                      Tentar novamente <IconReload className="ms-1 mt-n1" />
                    </button>
                    :
                    <></>
                  }


                  {logic.showPayment ? 
                    <div className="mt-4 mb-5 text-start card-payment">
                      <MetodoPagamentoComponente
                        configuration={logic.configuration}
                        handleSubmit={logic.handleSubmit}
                        disableFields={false}
                        formularioPagamento={logic.getForm}
                        loading={logic.loading}
                        alterarLoading={logic.alterarLoading}
                        iniCreditoAberto={true}
                        recaptchaRef={logic.recaptchaRef}
                      />
                    </div>
                    :
                    <></>
                  }
                </Col>
              </Row>
            </div>
            <div className="col-auto d-flex justify-content-center align-items-center p-0 mt-3 mt-lg-0"
            >
              <img
                className="responsive-img"
                src={logic.configuration.analysis?.image}
                alt="Analise"
              />
            </div>
          </Row>
        </Container>
      </main>

      <footer>
        <FooterComponente
          configuration={logic.configuration}
          divideTela={false}
        />
      </footer>
    </>
  );
}
