import { Button, Card, Carousel, Col, Container, Row } from 'react-bootstrap';
import { HeaderComponente } from '../../componentes/header.component';
import { FooterComponente } from '../../componentes/footer.component';
import { doubleToString } from '../../utils/utils';
import { Logic } from './logic';
import './style.scss';

export default function Inicio({ logic = Logic() }) {

  return (
    <>
      <main className="page-use-banner">
        <HeaderComponente
          configuration={logic.configuration}
          bgSolido={false}
          class={'page-inicio'}
        />
        <Container fluid="xxl" className="app-container">
          <Row className="h-responsive vh-100">
            <Col md={12} xl={5} className="message-col order-2 order-xl-1">
              <Row
                id="title-carousel"
                className="flex-nowrap ms-xl-5 justify-content-center justify-content-xl-start"
                dangerouslySetInnerHTML={{ __html: logic.carrossel?.title! }}
              />

              <Row className="content-start ms-xl-5">
                <Col className="mx-auto">
                  <Card className="card-plans">
                    <h5>Consultas por apenas</h5>
                    <Row>
                      <Col>
                        <div className="d-flex">
                          <span className="currency">R$</span>
                          <div>
                            <div className={`value ${logic.configuration.prices!.clinico_geral! > 0 ? '' : 'shimmer'}`}>
                              <div className="value">{doubleToString(logic.configuration.prices!.clinico_geral!).split(',')[0]}
                              </div>
                            </div>
                            <div className="fw-normal mt-n1 description">
                              Clínica geral
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex">
                          <span className="currency">R$</span>
                          <div>
                            <div className={`value ${logic.configuration.prices!.demais! > 0 ? '' : 'shimmer'}`}>
                              <div className="value">
                                {
                                  doubleToString(
                                    logic.configuration.prices!.demais!
                                  ).split(',')[0]
                                }
                              </div>
                            </div>
                            <div className="fw-normal mt-n1 description">
                              Demais áreas médicas
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card>

                  <Row>
                    <Col>
                      <div className={`${logic.mensalidade! > 0 ? '' : 'shimmer'}`}>
                        <p className="mt-1">
                          <small className="text-muted text-center">
                            * Na primeira mensalidade será cobrada a taxa de
                            <br />
                            adesão do cartão no valor de R${' '}
                            {doubleToString(logic.mensalidade)}.
                          </small>
                        </p>
                      </div>
                      <div className="d-xl-grid mb-4 mb-xl-5 pt-4 text-center button-start">
                        <Button
                          variant="secondary"
                          size="lg"
                          type="submit"
                          onClick={logic.toPagamento}
                        >
                          Peça já o seu
                        </Button>
                      </div>

                      <div
                        className="text-center"
                        dangerouslySetInnerHTML={{
                          __html: logic.configuration.helpTextHome!,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="ms-xl-1">
                <Col xl="auto" className="px-0 px-lx-2">
                  <footer className="use-banner-footer inicio">
                    <FooterComponente
                      configuration={logic.configuration}
                      divideTela={true}
                    />
                  </footer>
                </Col>
              </Row>
            </Col>

            <Col
              md={12}
              xl={7}
              className="banner-col text-center d-flex flex-column flex-xl-row align-items-center order-1 order-xl-2 d-flex justify-content-center position-relative"
            >
              <Carousel
                fade
                className="px-5 position-absolute d-flex align-items-center"
                wrap={false}
                indicators={false}
                interval={null}
                onSelect={(index) =>
                  logic.changeCarouselTitle(index)
                }
              >

                {logic.configuration.home?.carousel?.map((item) => {
                  return (
                    <Carousel.Item key={item.imageDesktop} className="text-center" style={{ marginTop: item.marginTop }}>
                      <img
                        className={item.imageMobile !== null ? "d-none d-xl-inline" : ""}
                        src={item.imageDesktop}
                        alt={item.imageMobile !== null ? item.imageMobile : item.imageDesktop}
                        height={item.height}
                        width={item.width}
                      />
                      <img
                        className={item.imageMobile !== null ? "d-xl-none" : "d-none"}
                        src={item.imageMobile}
                        alt={item.imageMobile !== null ? item.imageMobile : item.imageDesktop}
                        height={item.height}
                        width={item.width}
                      />
                    </Carousel.Item>
                  );
                })
                }

              </Carousel>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
}
