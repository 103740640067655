import axiosInstance from "../utils/axiosInstance";

export interface DataProps {
    IdPromotorVendas: string | null;
    notaNpsVendedor: number;
    observacao: string | null;
    canalVendas: string;
}

export const AvaliacaoService = () => {
    const sendNPS = async (data: DataProps) => {
        try {
            var response = await axiosInstance.post(`/avaliacaocsat`, JSON.stringify(data));
            return response.data;
        } catch (error) {
            return undefined;
        }
    };

    return { sendNPS };
}