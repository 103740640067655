import axiosInstance from "../utils/axiosInstance";
import { ServiceValuesModel } from "../modelos";

export const cashbackService = () => {
    const getEnergiaDeTodos = async (franquiaId: number) => {

        try {
            var response = await axiosInstance.get<ServiceValuesModel>(`/validate/cashback-energia-todos/${franquiaId}`);
            return response.data;
        } catch (error) {
            return {success: false, message: "Erro ao conectar com a API"};
        }
    };

    return { getEnergiaDeTodos };
}