import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';

interface ICheckout {
    adesao: number;
    setAdesao: Dispatch<SetStateAction<number>>;
    mensalidade: number;
    setMensalidade: Dispatch<SetStateAction<number>>;
    total: number;
    setTotal: Dispatch<SetStateAction<number>>;

    adesaoVoucher: number;
    setAdesaoVoucher: Dispatch<SetStateAction<number>>;
    mensalidadeVoucher: number;
    setMensalidadeVoucher: Dispatch<SetStateAction<number>>;
    totalVoucher: number;
    setTotalVoucher: Dispatch<SetStateAction<number>>;

    nome: string;
    setNome: Dispatch<SetStateAction<string>>;
    cpf: string;
    setCpf: Dispatch<SetStateAction<string>>;
    email: string;
    setEmail: Dispatch<SetStateAction<string>>;
    telefone: string;
    setTelefone: Dispatch<SetStateAction<string>>;
    maior_dezoito: boolean;
    setMaior_dezoito: Dispatch<SetStateAction<boolean>>;
    termos: boolean;
    setTermos: Dispatch<SetStateAction<boolean>>;
    termosSmsEmail: boolean;
    setTermosSmsEmail: Dispatch<SetStateAction<boolean>>;
    cep: string;
    setCep: Dispatch<SetStateAction<string>>;
    rua: string;
    setRua: Dispatch<SetStateAction<string>>;
    bairro: string;
    setBairro: Dispatch<SetStateAction<string>>;
    cidade: string;
    setCidade: Dispatch<SetStateAction<string>>;
    estado: string;
    setEstado: Dispatch<SetStateAction<string>>;
    numero: string;
    setNumero: Dispatch<SetStateAction<string>>;
    complemento: string;
    setComplemento: Dispatch<SetStateAction<string>>;
    franquiaId: number;
    setFranquiaId: Dispatch<SetStateAction<number>>;
    voucher: string;
    setVoucher: Dispatch<SetStateAction<string>>;
    addRefuturiza: boolean;
    setAddRefuturiza: Dispatch<SetStateAction<boolean>>;
    methodSelected: string;
    setMethodSelected: Dispatch<SetStateAction<string>>;
    cashbackEnergiadetodos: boolean;
    setCashbackEnergiadetodos: Dispatch<SetStateAction<boolean>>;
    isRefiliacao: boolean;
    setIsRefiliacao: Dispatch<SetStateAction<boolean>>;

    URLCurrent: string;
    setURLCurrent: Dispatch<SetStateAction<string>>;

    checkoutValueCDT: string;
    setCheckoutValueCDT: Dispatch<SetStateAction<string>>;

    utm_Source: string;
    setUtm_Source: Dispatch<SetStateAction<string>>;

    utm_Campaign: string;
    setUtm_Campaign: Dispatch<SetStateAction<string>>;
    
    utm_Content: string;
    setUtm_Content: Dispatch<SetStateAction<string>>;

    utm_Medium: string;
    setUtm_Medium: Dispatch<SetStateAction<string>>;

    utm_Term: string;
    setUtm_Term: Dispatch<SetStateAction<string>>;

    paramsURL: string;
    setParamsURL: Dispatch<SetStateAction<string>>;
}

export const CheckoutContext = createContext<ICheckout>({
    adesao: 0,
    setAdesao: () => console.warn('setAdesao not ready'),
    mensalidade: 0,
    setMensalidade: () => console.warn('setMensalidade not ready'),
    total: 0,
    setTotal: () => console.warn('setTotal not ready'),

    adesaoVoucher: 0,
    setAdesaoVoucher: () => console.warn('setAdesaoVoucher not ready'),
    mensalidadeVoucher: 0,
    setMensalidadeVoucher: () => console.warn('setMensalidadeVoucher not ready'),
    totalVoucher: 0,
    setTotalVoucher: () => console.warn('setTotalVoucher not ready'),

    nome: '',
    setNome: () => console.warn('setNome not ready'),
    cpf: '',
    setCpf: () => console.warn('setCpf not ready'),
    email: '',
    setEmail: () => console.warn('setEmail not ready'),
    telefone: '',
    setTelefone: () => console.warn('setTelefone not ready'),
    maior_dezoito: false,
    setMaior_dezoito: () => console.warn('setMaior_dezoito not ready'),
    termos: false,
    setTermos: () => console.warn('setTermos not ready'),
    termosSmsEmail: false,
    setTermosSmsEmail: () => console.warn('setTermosSmsEmail not ready'),
    cep: '',
    setCep: () => console.warn('setCep not ready'),
    rua: '',
    setRua: () => console.warn('setRua not ready'),
    bairro: '',
    setBairro: () => console.warn('setBairro not ready'),
    cidade: '',
    setCidade: () => console.warn('setCidade not ready'),
    estado: '',
    setEstado: () => console.warn('setEstado not ready'),
    numero: '',
    setNumero: () => console.warn('setNumero not ready'),
    complemento: '',
    setComplemento: () => console.warn('setComplemento not ready'),
    franquiaId: 0,
    setFranquiaId: () => console.warn('setFranquiaId not ready'),
    voucher: '',
    setVoucher: () => console.warn('setVoucher not ready'),
    addRefuturiza: false,
    setAddRefuturiza: () => console.warn('setAddRefuturiza not ready'),
    methodSelected: '',
    setMethodSelected: () => console.warn('setMethodSelected not ready'),
    cashbackEnergiadetodos: false,
    setCashbackEnergiadetodos: () => console.warn('setTermos not ready'),

    URLCurrent: '',
    setURLCurrent: () => console.warn('URLCurrent not ready'),
    isRefiliacao: false,
    setIsRefiliacao: () => console.warn('setAddRefuturiza not ready'),

    checkoutValueCDT: '',
    setCheckoutValueCDT: () => console.warn('checkoutValueCDT not ready'),

    utm_Source: '',
    setUtm_Source: () => console.warn('setUtmSource not ready'),
    utm_Campaign: '',
    setUtm_Campaign: () => console.warn('setUtmCampaign not ready'),
    utm_Content: '',
    setUtm_Content: () => console.warn('setContent not ready'),
    utm_Medium: '',
    setUtm_Medium: () => console.warn('setMedium not ready'),
    utm_Term: '',
    setUtm_Term: () => console.warn('setTerm not ready'),
    paramsURL: '',
    setParamsURL: () => console.warn('setParamsURL not ready'),


});

export const CheckoutProvider: FC = ({ children }) => {
    const [adesao, setAdesao] = useState<number>(0);
    const [mensalidade, setMensalidade] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);

    const [adesaoVoucher, setAdesaoVoucher] = useState<number>(0);
    const [mensalidadeVoucher, setMensalidadeVoucher] = useState<number>(0);
    const [totalVoucher, setTotalVoucher] = useState<number>(0);

    const [nome, setNome] = useState<string>('');
    const [cpf, setCpf] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [telefone, setTelefone] = useState<string>('');
    const [maior_dezoito, setMaior_dezoito] = useState<boolean>(false);
    const [termos, setTermos] = useState<boolean>(false);
    const [termosSmsEmail, setTermosSmsEmail] = useState<boolean>(false);
    const [cep, setCep] = useState<string>('');
    const [rua, setRua] = useState<string>('');
    const [bairro, setBairro] = useState<string>('');
    const [cidade, setCidade] = useState<string>('');
    const [estado, setEstado] = useState<string>('');
    const [numero, setNumero] = useState<string>('');
    const [complemento, setComplemento] = useState<string>('');
    const [franquiaId, setFranquiaId] = useState<number>(0);
    const [voucher, setVoucher] = useState<string>('');
    const [addRefuturiza, setAddRefuturiza] = useState<boolean>(false);
    const [isRefiliacao, setIsRefiliacao] = useState<boolean>(false);

    const [methodSelected, setMethodSelected] = useState<string>('');
    const [cashbackEnergiadetodos, setCashbackEnergiadetodos] = useState<boolean>(false);

    const [URLCurrent, setURLCurrent] = useState('');
    const [checkoutValueCDT, setCheckoutValueCDT] = useState('');

    const [utm_Source, setUtm_Source] = useState<string | null | any>(null);
    const [utm_Campaign, setUtm_Campaign] = useState<string | null | any>(null);
    const [utm_Content, setUtm_Content] = useState<string | null | any>(null);
    const [utm_Medium, setUtm_Medium] = useState<string | null | any>(null);
    const [utm_Term, setUtm_Term] = useState<string | null | any>(null);
    const [paramsURL, setParamsURL] = useState<string | null | any>(null);

    return (
        <CheckoutContext.Provider value={{
            adesao, setAdesao,
            mensalidade, setMensalidade,
            total, setTotal,

            adesaoVoucher, setAdesaoVoucher,
            mensalidadeVoucher, setMensalidadeVoucher,
            totalVoucher, setTotalVoucher,

            nome, setNome,
            cpf, setCpf,
            email, setEmail,
            telefone, setTelefone,
            maior_dezoito, setMaior_dezoito,
            termos, setTermos,
            termosSmsEmail, setTermosSmsEmail,
            cep, setCep,
            rua, setRua,
            bairro, setBairro,
            cidade, setCidade,
            estado, setEstado,
            numero, setNumero,
            complemento, setComplemento,
            franquiaId, setFranquiaId,
            voucher, setVoucher,
            addRefuturiza, setAddRefuturiza,
            methodSelected, setMethodSelected,
            cashbackEnergiadetodos, setCashbackEnergiadetodos,
            isRefiliacao, setIsRefiliacao,

            URLCurrent, setURLCurrent,
            checkoutValueCDT, setCheckoutValueCDT,

            utm_Source, setUtm_Source,
            utm_Campaign, setUtm_Campaign,
            utm_Content, setUtm_Content,
            utm_Medium, setUtm_Medium,
            utm_Term, setUtm_Term,
            paramsURL, setParamsURL
        }}>
            {children}
        </CheckoutContext.Provider>
    )
}